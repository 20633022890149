import React from "react";
import { connect } from "react-redux";
import { userHasAccess, text } from "../libs/helper";
import moment from "moment";

//service and redux-actions
import Api from "../Services/Api";
import CrudAction from "../redux/actions/CrudAction";
import CrudAddAction from "../redux/actions/CrudAddAction";
import CrudUpdateAction from "../redux/actions/CrudUpdateAction";
import CrudGetAction from "../redux/actions/CrudGetAction";
import CrudDeleteAction from "../redux/actions/CrudDeleteAction";
import DoAction from "../redux/actions/DoAction";
import CallAction from "../redux/actions/CallAction";
import SimpleCard from "./widgets/SimpleCard";
import ListingTable from "./widgets/ListingTable";
import ListingDataTable from "./widgets/ListingDataTable";
import LabelAndTextInput from "./widgets/LabelAndTextInput";
import ModuleForm from "./ModuleForm";
import Button from "./widgets/Button";
import { Alert, Tab, Tabs, Toast, Modal } from "react-bootstrap";
import ResourceSearchableLookup from "./widgets/ResourceSearchableLookup";
import ArrayLookup from "./widgets/ArrayLookup";
import Popup from "./widgets/Popup";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Footer from "./common/Footer";


const md5 = require("md5");

class ModuleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      columns: [],
      selectedRow: null,
      deletedRow: null,
      snackbar: false,
      snackbarText: "",
      data: [],
      page: 0,
      per_page: 20,
      total_page: 0,
      search: "",
      orderDirection: null,
      total: 0,
      open_add_dialog: false,
      open_edit_dialog: false,
      progress: false,
      tableRef: React.createRef(),
      confirm_delete: false,
      actionName: "",
      actionText: null,
      actionOpenWindow: false,
      actionType: null,
      actionOpts: null,
      actionComponent: null,
      confirm_action: false,
      open_action_component: false,
      other_action: null,
      filters: null,
      filterChanged: 0,
      fallback: null,
      autoloadFilter: false,
      refreshAccountWhenAdded: false, //use these flag to automatically refresh the account data whenever the add entry successfull
      refreshAccountWhenUpdated: false,
      total_rows: 0,
      showSidebar: window.innerWidth < 600 ? false : true,
      tabValue: 0,
      tableQuery: false,
      on_add_error: false,
      invalids: [],
      actionUri: null,
      inPlaceComponent: false,
      current: "listing",
      refresh: 0,
      last_request_ts: 0,
      response: null,
      action_component_payload: null,
      action_component_loading: false,
    };

    this.loadData = this.loadData.bind(this);
    this.handleListing = this.handleListing.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.handleFilterChanges = this.handleFilterChanges.bind(this);
    this.refreshEmbeddedComponents = this.refreshEmbeddedComponents.bind(this);
    this.renderAutoFilter = this.renderAutoFilter.bind(this);
    this.renderFallbackScreen = this.renderFallbackScreen.bind(this);
    this.handleRefreshAccount = this.handleRefreshAccount.bind(this);
    this.isDataEntryLimited = this.isDataEntryLimited.bind(this);
    this.checkAutoFilters = this.checkAutoFilters.bind(this);
   
  }
  loadData() {
    const { moduleProps, location, callAction, call_action } = this.props;
    const { page, per_page, search, total, orderDirection, filters } =
      this.state;

    let requestKey = md5(
      JSON.stringify({
        page,
        per_page,
        search,
        total,
        orderDirection,
        filters,
      })
    );

    let last_request = localStorage.getItem("last_request");

    if (last_request === requestKey) return;

    localStorage.setItem("last_request", last_request);

    if (!this.state.fetching) {
      callAction(call_action, moduleProps.name.split(" ").join("_") + "_load", {
        endpoint:
          typeof moduleProps.endpoint === "function"
            ? moduleProps.endpoint(location.state.data)
            : moduleProps.endpoint,
        scenario: "GET",
        data: {
          ...filters,
          ...this.getFilters(),
          page: parseInt(page),
          per_page: parseInt(per_page),
          pageSize: parseInt(per_page),
          totalCount: parseInt(total ? total : 0),
          orderDirection: orderDirection,
          search: search,
        },
      });
      this.setState({
        progress: true,
        last_request_ts: moment().unix(),
      });
    }
  }
  handleAction(name, act, rowData) {
    const { callAction, call_action } = this.props;
    const { actionOpts } = this.state;



    if (typeof act.trigger === "function") {
      let act_opt = Object.apply(actionOpts);
      if (typeof act.redirect !== "undefined") {
        if (act.redirect) this.props.history.push(act.uri(rowData));
        return;
      }
      act_opt[act.name] = {
        openWindow:
          typeof act.openWindow !== "undefined" ? act.openWindow : false,
      };
      this.setState({
        selectedRow: rowData,
        actionName: act.label,
        actionOpts: act_opt,
        actionOpenWindow: typeof act.openWindow !== "undefined" ? act.openWindow : false,
        actionUri: typeof act.uri !== "undefined" ? act.uri : null,
        confirm_action: typeof act.component === "undefined" ? true : false,
        open_action_component:
          typeof act.component !== "undefined"
            ? typeof act.type === "undefined"
              ? true
              : false
            : false,
        other_action: act,
      });
    } else if (typeof act.render !== "undefined") {
      let act_opt = Object.apply(actionOpts);

      if (typeof act.redirect !== "undefined") {
        if (act.redirect) this.props.history.push(act.uri(rowData));
        return;
      }
      act_opt[act.name] = {
        openWindow:
          typeof act.openWindow !== "undefined" ? act.openWindow : false,
      };

      this.setState({
        selectedRow: rowData,
        actionName: act.label,
        actionOpts: act_opt,
        actionUri: typeof act.uri !== "undefined" ? act.uri : null,
        confirm_action:
          typeof act.component === "undefined"
            ? typeof act.type === "undefined"
              ? true
              : false
            : false,
        open_action_component:
          typeof act.component !== "undefined"
            ? typeof act.type === "undefined"
              ? true
              : false
            : false,
        other_action: act,
        actionComponent: act.component,
      });
    } else {
      if (typeof act.redirect !== "undefined") {
        if (act.redirect) this.props.history.push(act.uri(rowData));
        return;
      }

      this.setState({
        selectedRow: rowData,
        actionName: act.label,
        actionText: act.text,
        actionUri: act.uri,
        actionType: act.type,
        actionOpenWindow:
          typeof act.openWindow !== "undefined" ? act.openWindow : false,
        confirm_action:
          typeof act.component === "undefined"
            ? typeof act.type === "undefined"
              ? true
              : false
            : false,
        open_action_component:
          typeof act.component !== "undefined" ? (
            typeof act.type === "undefined" ? (
              true
            ) : (
              <i className="i-Magnifi-Glass1" />
            )
          ) : (
            false
          ),
        other_action: act,
        actionComponent: act.component,
      });
    }
  }
  componentDidMount() {
    const { callAction, call_action, moduleProps } = this.props;

    let autofilters = this.checkAutoFilters();
    if (!autofilters) return this.loadData();
    if (autofilters.length === 0) return this.loadData();
  }

  componentDidUpdate(_props, _states) {
    const {
      crud_add,
      crud_delete,
      do_action,
      crud_get,
      crud,
      crud_update,
      refresh_account,
      call_action,
      moduleProps,
    } = this.props;

    const { page, per_page, search, tableRef, filters, filterChanged } =
      this.state;

   
    if (_states.page !== this.state.page) {
      this.loadData();
    }
    if (_states.search !== this.state.search) {
      this.loadData();
    }
    if (_states.refresh !== this.state.refresh) {
      setTimeout(() => {
        this.loadData();
      }, 2000);
    }
    if (_props.crud_add !== crud_add)
      this.handleCrudAdd(_props.crud_add, crud_add);

    if (_props.crud_update !== crud_update)
      this.handleCrudUpdate(_props.crud_update, crud_update);

    if (_props.crud_delete !== crud_delete)
      this.handleCrudDelete(_props.crud_delete, crud_delete);

    if (_props.do_action !== do_action)
      this.handleDoAction(_props.do_action, do_action);

    if (_states.filterChanged !== filterChanged) {
      this.handleFilterChanges();
    }
    /*if (_states.filters !== filters) {
       this.handleFilterChanges();
    }*/

    /*if (_props.refresh_account.payload !== refresh_account.payload)
      this.handleRefreshAccount(refresh_account.payload);*/

    if (call_action.custom_action !== _props.call_action.custom_action) {
      this.handleCustomAction(call_action.custom_action);
    }

    
    if (call_action.action_component_load !== _props.call_action.action_component_load) {
      this.handleActionComponentPayload(call_action.action_component_load);
    }
    const _key = moduleProps.name.split(" ").join("_") + "_load";
    if (
      this.state.progress === true &&
      _props.call_action[_key] !== call_action[_key] &&
      call_action[_key] !== null
    ) {
     
      this.setState({ progress: false });
      this.handleListing(call_action[_key]);
    }
  }
  setupFilterDefaultValue() {
    const { moduleProps } = this.props;
    const { filters, filterChanged } = this.state;

    let f = filters;
    if (f === null) f = {};

    moduleProps.filters.map((item, index) => {
      if (typeof item.access !== "undefined") {
        if (item.access.length > 0) {
          if (!userHasAccess(item.access)) return;
        }
      }
      if (typeof item.defaultValue === "undefined") return;
      if (typeof f[item.parameterName] === "undefined") {
        f[item.parameterName] = item.defaultValue;
      }
  
    });

    this.setState({
      filters: f,
      filterChanged: filterChanged + 1,
    });

  }
  handleListing(payload) {
    let { page, per_page, search } = this.state;

    if (typeof payload === "undefined") return;
    if (payload === null) return;
    const { status, data, error } = payload;
    if (status === 403) return (document.location = "/login");
    if (status === 0) {
      this.setState({
        snackbar: true,
        snackbarText: error,
      });
      return;
    }

    this.setState({
      data:
        status === 1
          ? data.map((data, idx) => {
              let rowNumber = payload.page * per_page + (idx + 1);
              return {
                rowNumber,
                ...data,
              };
            })
          : [],
      //page: payload.page,
      total: payload.total,
      total_page: Math.ceil(payload.total / per_page),
      response: payload,
    });
  }
  handleActionComponentPayload(payload) {
  

    if (typeof payload === "undefined") return;
    if (payload === null) return;
    
    this.setState({
      action_component_loading: false,
      action_component_payload: payload
    });

    this.loadData();
  }
  handleCustomAction(payload) {
    const { selectedRow } = this.state;

    if (typeof payload === "undefined") return;
    if (payload === null) return;
    let title = "Data";
    if (selectedRow !== null) {
      if (typeof selectedRow.nama !== "undefined") {
        title = selectedRow.nama;
      }
      if (typeof selectedRow.name !== "undefined") {
        title = selectedRow.name;
      }
      if (typeof selectedRow.title !== "undefined") {
        title = selectedRow.title;
      }
      if (typeof selectedRow.kode !== "undefined") {
        title = selectedRow.kode;
      }
    }
    let snackbar = true;
    let snackbarText =
      title !== null
        ? `${title} tidak berhasil disimpan. ${
            payload.error ? payload.error : ``
          }`
        : "Tidak berhasil menyimpan perubahan. Silahkan coba kembali !";
    if (payload.status === 1) {
      snackbarText =
        title !== null
          ? `Perubahan ${title} berhasil disimpan. ${
              payload.error ? payload.error : ``
            }`
          : "Perubahan data berhasil disimpan !";
    }
    this.setState({
      snackbar,
      snackbarText,
      actionType: null,
      actionComponent: null,
      open_action_component: false,
    });
    this.loadData();
  }
  handlePageClick = (data) => {
    let pageNumber = data.selected;
  };
  shouldComponentUpdate(next_props, next_states) {
    if (next_states.tableQuery !== this.state.tableQuery) {
      return false;
    }
    return true;
  }
  handleRefreshAccount(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    try {
      localStorage.setItem("roles", payload.roles);
      localStorage.setItem("session_id", payload.user.session_id);
      localStorage.setItem("name", payload.user.name);
      localStorage.setItem("gid", payload.gid);
      localStorage.setItem("mdid", payload.mdid);
      localStorage.setItem("oid", payload.oid);
      if (payload.pemda !== null) {
        localStorage.setItem("profil_id", payload.pemda.id);
        localStorage.setItem("profil_name", payload.pemda.nama);
        localStorage.setItem("pemda", payload.pemda.nama);
      }
      localStorage.setItem("daerah", payload.daerah.nama);
    } catch (err) {}
  }
  handleFilterChanges() {
    try {
      //this.state.tableRef.current.onQueryChange();
      this.loadData();
    } catch (err) {}

    //also refresh embed components if any
    this.refreshEmbeddedComponents();
  }
  handleDoAction(old, state) {
    if (state.fetching) {
      this.setState({
        confirm_action: false,
        progress: true,
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.setState({
        snackbar: true,
        snackbarText: "The action completed successfully !",
      });
    }
    if (old.error !== state.error) {
      if (state.error) {
        this.setState({
          snackbar: true,
          snackbarText: "The action cannot be executed, please try again!",
        });
      }
    }
  }
  handleCrudAdd(old, state) {
    const { moduleProps } = this.props;
    const { refreshAccountWhenAdded } = this.state;

    if (state.fetching) {
      this.setState({
        open_add_dialog: false,
        progress: true,
        current: "listing",
        page: 0,
      });
    } else {
      this.setState({ progress: false, refresh: this.state.refresh + 1 });
    }

    if (state.payload !== null) {
      //this.loadData();
      this.setState({
        snackbar: true,
        snackbarText: "Data berhasil dibuat!",
      });
      if (moduleProps.refreshAccountWhenAdded) {
       // this.props.refreshAccount();
      }
      this.loadData();
    }
  }
  handleCrudUpdate(old, state) {
    const { selectedRow } = this.state;
    const { moduleProps } = this.props;

    if (state.fetching) {
      this.setState({
        open_edit_dialog: false,
        progress: true,
        current: "listing",
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.setState({
        snackbar: true,
        refresh: this.state.refresh + 1,
        snackbarText: "Perubahan Data telah berhasil disimpan !",
      });
      if (moduleProps.refreshAccountWhenUpdated) {
       //this.props.refreshAccount();
      }
      this.loadData();
    }
  }
  handleCrudDelete(old, state) {
    const { deletedRow } = this.state;
    const { moduleProps } = this.props;
    if (state.fetching) {
      this.setState({
        confirm_delete: false,
        progress: true,
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.loadData();
      this.setState({
        snackbar: true,
        snackbarText: "Data telah berhasil dihapus!`",
      });
    }
  }

  refreshEmbeddedComponents() {
    const { callAction, moduleProps, call_action } = this.props;
    const { filters } = this.state;

    // console.log("refresh embedded");
    if (typeof moduleProps.addBefore === "undefined") return;

    //for each components. check if there's a custom component
    //retrieve the endpoint, and execute the CallAction

    //1. components that attached before the main component position
    moduleProps.addBefore.map((EmbeddedComponent, index) => {
      if (typeof EmbeddedComponent.component !== "undefined") {
        let filterValue = this.getFilterValue(EmbeddedComponent.connectFilter);
        if (typeof EmbeddedComponent.endpoint === "function") {
          let endpoint = EmbeddedComponent.endpoint(filterValue);
          callAction(call_action, EmbeddedComponent.name, {
            endpoint,
          });
        }
      }
    });

    if (typeof moduleProps.addAfter === "undefined") return;
    //2. components that attached after the main component position
    moduleProps.addAfter.map((EmbeddedComponent, index) => {
      if (typeof EmbeddedComponent.component !== "undefined") {
        let filterValue = this.getFilterValue(EmbeddedComponent.connectFilter);
        let endpoint = EmbeddedComponent.endpoint(filterValue);
        callAction(call_action, EmbeddedComponent.name, {
          endpoint,
        });
      }
    });
  }

  /*
  {
          search: query.search,
          per_page: parseInt(query.pageSize),
          page: parseInt(query.page)
        },*/

  getLabel(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i].label;
      }
    }
  }
  displayedInListing(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (
          typeof fields[i].visible !== "undefined" &&
          fields[i].visible === false
        )
          return false;
      }
    }

    return true;
  }
  isImage(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (fields[i].type === "image") return true;
      }
    }
    return;
  }
  getField(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i];
      }
    }
    return false;
  }
 
  getData(props) {
    const { crud, moduleProps } = props;
    let data = [];
    if (!moduleProps.crud) {
      if (typeof crud === "undefined") return [[], []];
      if (typeof crud.payload === "undefined") return [[], []];
      if (crud.payload === null) return [[], []];
      data = crud.payload.data;
    }

    let cols = [];
    if (data.length > 0) {
      let row = data[0];
      for (let k in row) {
        let field = this.getField(moduleProps.fields, k);
        if (!field) continue;
        cols = this.compileColumns(cols, field, k);
      }

      /*this.setState({
        columns: cols
      });*/
    }
    if (cols.length === 0) {
      //if cols still empty, then we use the defined columns setup
      moduleProps.fields.map((field) => {
        let k = field.name;
        cols = this.compileColumns(cols, field, k);
      });
    }

    return [cols, data, 5];
  }
  isVisible(field) {
    if (typeof field.visible === "undefined") return true;
    return field.visible;
  }
  compileColumns(cols, field, k) {
    const { moduleProps } = this.props;

    let fields = [];

    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return cols;
      }
    }

    if (!this.isVisible(field)) return cols;

    // if (this.displayedInListing(fields, k)) {

    if (this.isImage(fields, k)) {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return <img src={rowData[k]} height={70} />;
        },
      });
    } else if (field.type === "number") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (rowData[k] === null) return "-";
          if (typeof field.resource_label !== "undefined") {
            return field.resource_label(rowData);
          }
          return rowData[k]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
      });
    } else if (field.type === "enum") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return field.values[rowData[k]];
        },
      });
    } else if (field.type === "datetime") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "date") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "lookup") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "undefined")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          if (typeof field.resource_label !== "function")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          return field.resource_label(rowData);
        },
      });
    } else if (field.type === "auto") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "radio") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "textarea") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: rowData[field.name] }}
            ></div>
          );
        },
      });
    } else if (field.type === "richtext") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: rowData[field.name] }}
            ></div>
          );
        },
      });
    } else if (field.type === "image") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return (
            <div>
              <img src={rowData[field.name]} height={100} />
            </div>
          );
        },
      });
    } else if (field.type === "action") {
      cols.push({
        field: k,
        headerStyle: {
          textAlign: "center",
        },
        title: field.label,
        render: (rowData) => {
          return (
            <div style={{ textAlign: "center" }}>
              {/*} <Tooltip title={field.label}>
                <IconButton
                  className={{ marginRight: 15 }}
                  onClick={(event, rowData) => {
                    this.setState({
                      selectedRow: rowData,
                      actionName: field.name,
                      actionText: field.text,
                      confirm_action:
                        typeof field.component === "undefined" ? true : false,
                      open_action_component:
                        typeof field.component !== "undefined" ? true : false,
                      other_action: field,
                    });
                  }}
                >
                  <Icon fontSize="small">{field.icon}</Icon>
                </IconButton>
                </Tooltip>{*/}
            </div>
          );
        },
      });
    } else {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    }
    // }
    if (cols[0].title !== "No.") {
      cols.unshift({
        title: "No.",
        render: (rowData) => rowData.tableData.id + 1 + ".",
      });
    }

    return cols;
  }
  getFilters() {
    const { moduleProps, location } = this.props;

    if (typeof moduleProps.parentFilters === "undefined") return;

    let filters = {};
    moduleProps.parentFilters.map((item) => {
      filters[item.field] = item.value(location.state.data);
    });

    return filters;
  }
  getFilterValue(filterName) {
    if (typeof this.state.filters === "undefined") return;
    if (this.state.filters === null) return;
    if (typeof this.state.filters[filterName] === "undefined") return;
    return this.state.filters[filterName];
  }

  renderAutoFilter(props) {
    const { moduleProps, item } = props;
    const { fallback, filters, filterChanged, autoloadFilter } = this.state;

    let data = item.autoValue();

    //add crud filter automatically
    let f = filters;
    if (f === null) f = {};
    if (typeof f[item.parameterName] === "undefined") {
      f[item.parameterName] = data.value;
      this.setState({
        filters: f,
        filterChanged: filterChanged + 1,
      });
    }

    if (data.label === null) {
      if (fallback === null)
        this.setState({
          fallback: item.name,
        });
      return false;
    }
    if (data.label.length === 0) return;

    return (
      <div key={data.label}>
        <h2 style={{ fontWeight: 500, fontSize: "120%" }}>{data.label}</h2>
      </div>
    );
  }
  checkAutoFilters() {
    const { moduleProps } = this.props;
    if (typeof moduleProps.filters !== "undefined") {
      return moduleProps.filters.filter((item, index) => {
        if (typeof item.access !== "undefined") {
          if (item.access.length > 0) {
            if (!userHasAccess(item.access)) return;
          }
        }
        if (item.resource === false && typeof item.autoValue !== "undefined") {
          //f[item.parameterName] = item.autoValue().value;
          return true;
        }
      });
    }
  }
  renderFilters() {
    const { moduleProps } = this.props;
    const { filters, filterChanged, fallback } = this.state;

    if (typeof moduleProps.filters !== "undefined") {
      return moduleProps.filters.map((item, index) => {
        if (typeof item.access !== "undefined") {
          if (item.access.length > 0) {
            if (!userHasAccess(item.access)) return null;
          }
        }
        if (fallback !== null) return null;

        if (item.resource === false && typeof item.autoValue !== "undefined") {
          //f[item.parameterName] = item.autoValue().value;
          return this.renderAutoFilter({
            moduleProps,
            item,
          });
        }
        return (
          <div key={"filter-" + index} style={{ marginBottom: 5 }}>
            <h5>{item.name}</h5>
            {typeof item.resource !== "undefined" ? (
              <ResourceSearchableLookup
                resource={item.resource}
                id={item.name}
                key={index}
                label={item.label}
                values={item.values}
                field={item}
                onChange={(value) => {
                  let f = filters;
                  if (f === null) f = {};
                  f[item.parameterName] = value;
                  this.setState({
                    filters: f,
                    filterChanged: filterChanged + 1,
                  });
                }}
              />
            ) : (
              <ArrayLookup
                id={item.name}
                key={index}
                label={item.label}
                values={item.values}
                defaultValue={item.defaultValue}
                field={item}
                onChange={(value) => {
                  let f = filters;
                  if (f === null) f = {};
                  f[item.parameterName] = value;

                  this.setState({
                    filters: f,
                    filterChanged: filterChanged + 1,
                  });
                }}
              />
            )}
          </div>
        );
      });
    }
  }

  renderTabs() {
    const { moduleProps, history } = this.props;
    const { tabValue, filters, filterChanged } = this.state;

    if (typeof moduleProps.tabs === "undefined") return;
    if (moduleProps.tabs === null) return;
    return (
      <Tabs
        defaultActiveKey="0"
        id="uncontrolled-tab-example"
        onSelect={(newValue, evt) => {
          let f = filters;
          if (f === null) f = {};

          f[moduleProps.tabs.parameterName] =
            moduleProps.tabs.values[newValue].value > -1
              ? moduleProps.tabs.values[newValue].value
              : null;

          this.setState({
            filters: f,
            tabValue: newValue,
            filterChanged: filterChanged + 1,
          });
        }}
      >
        {moduleProps.tabs.values.map((tab, index) => {
          return <Tab key={`tab-${index}`} eventKey={index} title={tab.text} />;
        })}
      </Tabs>
    );
  }
  renderFallbackScreen() {
    const { moduleProps, history } = this.props;
    const { fallback } = this.state;
    let filter = {};
    for (let k in moduleProps.filters) {
      if (
        moduleProps.filters[k].name === fallback &&
        moduleProps.filters[k].resource === false
      ) {
        if (typeof moduleProps.filters[k].fallback === "undefined") return null;
        return moduleProps.filters[k].fallback.component({
          onNextUrl: () => {
            moduleProps.filters[k].fallback.addButton
              ? this.setState({
                  open_add_dialog: true,
                })
              : history.push(
                  process.env.PUBLIC_URL +
                    moduleProps.filters[k].fallback.next_url
                );
          },
        });
      }
    }
    return <div>Maaf, halaman tidak tersedia !</div>;
  }
  isDataEntryLimited() {
    const { moduleProps } = this.props;
    const { tableRef } = this.state;

    if (
      typeof moduleProps.addLimit !== "undefined" &&
      moduleProps.addLimit <= tableRef.total_rows
    ) {
      if (userHasAccess(moduleProps.addLimitAccess)) return true;
    }
    return false;
  }

  render() {
    const {
      classes,
      moduleProps,
      modules,
      crud,
      crud_add,
      crud_edit,
      crud_delete,
      crudUpdateAction,
      crudDeleteAction,
      crudAddAction,
      doAction,
      location,
      history,
      call_action,
      callAction,
    } = this.props;

    const {
      userList,
      columns,
      open_add_dialog,
      open_edit_dialog,
      progress,
      selectedRow,
      confirm_delete,
      deletedRow,
      snackbar,
      snackbarText,
      tableRef,
      actionName,
      confirm_action,
      open_action_component,
      other_action,
      fallback,
      total_rows,
      actionText,
      actionUri,
      actionOpts,
      cardList1 = [],
      topSellingProduct = [],
      newUserList = [],
      userActivity = [],
      data,
      page,
      per_page,
      total_page,
      total,
      popup_add_dialog,
      popup_edit_dialog,
      current,
      fetching,
    } = this.state;

    let filters = [];
    if (fetching) return <div className="spinner spinner-primary mr-3"></div>;
    //parentFilter
    if (
      typeof location.state !== "undefined" &&
      typeof location.state.parent !== "undefined"
    ) {
      moduleProps.parentFilters.map((item) => {
        filters.push(item);
      });
    }

    if (open_action_component) {
      if (this.state.actionType !== "inplace") {
        const componentUri = moduleProps.prefix
          ? process.env.PUBLIC_URL +
            moduleProps.prefix +
            "/" +
            other_action.component
          : process.env.PUBLIC_URL + "/" + other_action.component;

        history.push(componentUri, {
          action: other_action.component,
          data: selectedRow,
          parent: {
            slug: moduleProps.slug,
            name: moduleProps.name,
          },
        });
      }
      // console.log("transforms", JSON.stringify(moduleProps.transforms),other_action.component,selectedRow);
    }
    return (
      <div>
        <TopLoggedInNav history={history} modules={modules} />
        <div className={`container-fluid`} >
          <div className={`row`}>
          <div  className={`col-md-12 col-xs-12`}>
        <div style={{marginTop:120,paddingBottom:60,minHeight:600}}>
        {this.state.actionType !== "inplace" &&
        typeof moduleProps.description !== "undefined" ? (
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
              <div className="card-body text-center">
                <div className="content">
                  <div
                    className={`text-muted mt-2 mb-0 text-capitalize`}
                    style={{ marginBottom: 30 }}
                    dangerouslySetInnerHTML={{
                      __html: moduleProps.description,
                    }}
                  ></div>{" "}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/*back button */}
        {typeof moduleProps.backTo !== "undefined" ? (
          <Button
            variant="contained"
            color={"primary"}
            style={{ marginBottom: 10 }}
            onClick={() => {
              if (moduleProps.prefix)
                history.push(
                  process.env.PUBLIC_URL +
                    moduleProps.prefix +
                    "/" +
                    moduleProps.backTo
                );
              else
                history.push(process.env.PUBLIC_URL + "/" + moduleProps.backTo);
            }}
            title={`Kembali`}
          ></Button>
        ) : null}
        {current === "listing" && typeof moduleProps.addBefore !== "undefined"
          ? moduleProps.addBefore.map((EmbeddedComponent, index) => {
              //console.log("visibility", EmbeddedComponent.name, EmbeddedComponent.visible)
              if (typeof EmbeddedComponent.visible !== "undefined") {
                if (!EmbeddedComponent.visible) return null;
              }
              if (typeof EmbeddedComponent.component !== "undefined") {
                let filterValue = this.getFilterValue(
                  EmbeddedComponent.connectFilter
                );

                if (
                  typeof filterValue === "undefined" &&
                  !EmbeddedComponent.static
                )
                  return null;
                if (filterValue === null && !EmbeddedComponent.static)
                  return null;

                return (
                  <div
                    key={EmbeddedComponent.name}
                    style={{
                      marginBottom: 15,

                      padding: 10,
                    }}
                  >
                    <EmbeddedComponent.component
                      callAction={callAction}
                      call_action={call_action}
                      filterValue={filterValue}
                      payload={this.state.response}
                      defaults={
                        typeof EmbeddedComponent.defaults === "function"
                          ? EmbeddedComponent.defaults(
                              location.state ? location.state.data : null
                            )
                          : EmbeddedComponent.defaults
                      }
                      data={
                        typeof call_action[EmbeddedComponent.name] !==
                        "undefined"
                          ? call_action[EmbeddedComponent.name]
                          : null
                      }
                      parent={
                        typeof location.state !== "undefined"
                          ? location.state.parent
                          : null
                      }
                      transforms={moduleProps.transforms}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={EmbeddedComponent.name}
                    id={index}
                    style={{
                      marginBottom: 15,
                      border: "1px solid #ccc",
                      padding: 10,
                    }}
                  >
                    <EmbeddedComponent
                      data={
                        typeof location.state !== "undefined"
                          ? location.state.data
                          : null
                      }
                      parent={
                        typeof location.state !== "undefined"
                          ? location.state.parent
                          : null
                      }
                      transforms={moduleProps.transforms}
                    />
                  </div>
                );
              }
            })
          : ""}
        {current === "listing" && this.renderTabs()}
       
        {current === "listing" && (
          <SimpleCard title={`${total} ${moduleProps.resultText ? moduleProps.resultText : moduleProps.name} ditemukan.`}>
            <div className="d-flex justify-content-end align-items-center">
              {typeof moduleProps.downloadXLS !== 'undefined' && <div style={{marginRight:30,paddingTop:13}}>
                <Button title={`Unduh XLS`} onClick={()=>{
                  window.open(moduleProps.downloadXLS(this.state.filters));
                }}/>
              </div>}
              
         
              <LabelAndTextInput
                label={""}
                placeholder={`cari`}
                onChange={(text) => {}}
                onEnterKey={(text) => {
                  this.setState({
                    search: text,
                  });
                }}
              ></LabelAndTextInput>
              {typeof moduleProps.add === "undefined" ||
              (moduleProps.add === true &&
                fallback === null &&
                !this.isDataEntryLimited()) ? (
                <div style={{ marginTop: 8, marginLeft: 10 }}>
                  <Button
                    title={`Tambah ${moduleProps.name}`}
                    onClick={() => {
                      this.setState({
                        current: "add",
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
            {this.renderFilters()}
            {progress && <div className="spinner spinner-primary mr-3"></div>}
            {this.state.fetching && (
              <div className="spinner spinner-primary mr-3"></div>
            )}

            {!this.state.fetching && (
              <ListingTable
                moduleProps={moduleProps}
                data={data}
                page={page}
                per_page={per_page}
                state={typeof location.state !== "undefined"
                ? location.state.data
                : null}
                total_page={total_page}
                total={total}
                onSort={(props) => {}}
                onPageChange={(page) => {
                  this.setState({
                    page,
                  });
                }}
                onEdit={(input) => {
                  this.setState({
                    selectedRow: input,
                    open_edit_dialog: true,
                    current: "edit",
                  });
                }}
                onDelete={(input) => {
                  this.setState({
                    deletedRow: input,
                    confirm_delete: true,
                  });
                }}
                onAction={(name, action, rowData) => {
                  this.handleAction(name, action, rowData);
                }}
              />
            )}
          </SimpleCard>
        )}

        {current === "actionComponent" && (
          <this.state.actionComponent
            moduleProps={this.props.moduleProps}
            data={this.state.selectedRow}
            loadActionComponent={(options)=>{
              this.props.callAction(this.props.call_action, 'action_component_load',options);
              this.setState({
                action_component_loading: true
              })
            }}
            progress={this.state.action_component_loading}
            onLoadActionComponentPayload={this.state.action_component_payload}
            onCancel={() => {
              this.setState({
                actionType: null,
                actionComponent: null,
                open_action_component: false,
              });
            }}
            onSubmit={(input) => {
              other_action.onSubmit(
                this.props.callAction,
                this.props.call_action,
                input,
                this.state.selectedRow
              );
            }}
          />
        )}
        {typeof moduleProps.addAfter !== "undefined"
          ? moduleProps.addAfter.map((EmbeddedComponent, index) => {
              if (typeof EmbeddedComponent.component !== "undefined") {
                let filterValue = this.getFilterValue(
                  EmbeddedComponent.connectFilter
                );

                if (typeof filterValue === "undefined") return null;
                if (filterValue === null) return null;
                return (
                  <div
                    id={index}
                    style={{
                      marginBottom: 15,
                      border: "1px solid #ccc",
                      padding: 10,
                    }}
                  >
                    <EmbeddedComponent.component
                      data={
                        typeof call_action[EmbeddedComponent.name] !==
                        "undefined"
                          ? call_action[EmbeddedComponent.name]
                          : null
                      }
                      parent={
                        typeof location.state !== "undefined"
                          ? location.state.parent
                          : null
                      }
                      transforms={moduleProps.transforms}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    id={index}
                    style={{
                      marginBottom: 15,
                      border: "1px solid #ccc",
                      padding: 10,
                    }}
                  >
                    <EmbeddedComponent
                      data={
                        typeof location.state !== "undefined"
                          ? location.state.data
                          : null
                      }
                      parent={
                        typeof location.state !== "undefined"
                          ? location.state.parent
                          : null
                      }
                      transforms={moduleProps.transforms}
                    />
                  </div>
                );
              }
            })
          : ""}
        {current === "add" ? (
          <ModuleForm
            title={`Tambah ${moduleProps.name}`}
            moduleProps={moduleProps}
            data={
              typeof location.state !== "undefined" &&
              typeof location.state.data !== "undefined"
                ? location.state.data
                : null
            }
            submitText={`Simpan`}
            onSubmit={(values, translations) => {
              console.log("submit", { values });
              crudAddAction(
                moduleProps,
                typeof moduleProps.beforeInsert !== "undefined"
                  ? moduleProps.beforeInsert(values)
                  : values,
                translations
              );
            }}
            cancelText={`Batal`}
            onCancel={() => {
              this.setState({
                current: "listing",
              });
            }}
            onSort={(sortKey) => {}}
            onWordcountError={(words) => {
              this.setState({
                snackbar: true,
                snackbarText: `mohon ketik minimal 300 kata untuk: ${words.join(
                  ","
                )}`,
              });
            }}
          />
        ) : null}
        {current === "edit" ? (
          <ModuleForm
            title={`Edit ${moduleProps.name}`}
            moduleProps={moduleProps}
            data={selectedRow}
            submitText={`Simpan`}
            onSubmit={(values, translations) => {
              crudUpdateAction(
                moduleProps,
                selectedRow[
                  typeof moduleProps.updateKey !== "undefined"
                    ? moduleProps.updateKey
                    : "id"
                ],
                typeof moduleProps.beforeUpdate !== "undefined"
                  ? moduleProps.beforeUpdate(values)
                  : values,
                translations
              );
            }}
            cancelText={`Batal`}
            onCancel={() => {
              this.setState({
                current: "listing",
              });
            }}
            onWordcountError={(words) => {
              this.setState({
                snackbar: true,
                snackbarText: `mohon ketik minimal 300 kata untuk: ${words.join(
                  ","
                )}`,
              });
            }}
          />
        ) : null}

        {confirm_delete && (
          <Popup
            title={`Hapus Data`}
            text={`Anda yakin menghapus data ini?`}
            onConfirm={() => {
              this.setState({
                confirm_delete: false,
              });
              crudDeleteAction(moduleProps, deletedRow.id);
            }}
            onCancel={() => {
              this.setState({
                confirm_delete: false,
              });
            }}
          />
        )}

        {confirm_action && (
          <Popup
            title={actionName}
            text={
              selectedRow !== null
                ? actionText
                  ? actionText(selectedRow)
                  : actionName +
                    " " +
                    selectedRow[moduleProps.updateLabel] +
                    ". Apakah anda yakin ? "
                : ""
            }
            onConfirm={() => {
              if (actionUri) {
                if (this.state.actionOpenWindow) {
                  let url = actionUri(selectedRow);
                  window.open(url);
                } else {
                  callAction(call_action, "custom_action", {
                    endpoint: actionUri(selectedRow),
                    scenario: "GET",
                  });
                }
              } else {
                doAction(moduleProps, actionName, selectedRow.id);
              }
              this.setState({
                confirm_action: false,
              });
            }}
            onCancel={() => {
              this.setState({
                confirm_action: false,
              });
            }}
          />
        )}

        <Modal
          show={this.state.snackbar}
          onHide={() => {
            this.setState({ snackbar: false });
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>{this.state.snackbarText}</Modal.Body>
          <Modal.Footer>
            <Button
              title={"OK"}
              variant="primary"
              onClick={() => {
                this.setState({ snackbar: false });
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
         
        </div>
     </div>
     <Footer />
      </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    crud,
    crud_add,
    crud_delete,
    crud_update,
    crud_get,
    do_action,
    call_action,
    refresh_account,
  } = state;
  return {
    crud,
    crud_add,
    crud_delete,
    crud_update,
    crud_get,
    do_action,
    call_action,
    refresh_account,
  };
};

const mapDispatchToProps = (dispatch) => ({
  crudAction: (service, data) => dispatch(CrudAction(service, data)),
  crudAddAction: (service, data, translations) =>
    dispatch(CrudAddAction(service, data, translations)),
  crudUpdateAction: (service, id, data, translations) =>
    dispatch(CrudUpdateAction(service, id, data, translations)),
  crudDeleteAction: (service, id, data) =>
    dispatch(CrudDeleteAction(service, id, data)),
  crudGetAction: (service, id, data) =>
    dispatch(CrudGetAction(service, id, data)),
  doAction: (service, actionName, id) =>
    dispatch(DoAction({ service, actionName, id })),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleContainer);
