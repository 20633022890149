import React, {useState,useEffect} from "react";
import ModuleForm from "../../Components/ModuleForm";
import { showMessage, hasValue } from "../../libs/helper";
import styled from "styled-components";
import Button from "../../Parts/Widgets/Button";

const Snackbar = styled.div`
padding:15px;
background:#cc0000;
color:white;
`;

const MessageBox = styled.div`
  padding:15px;
  margin-bottom:100px;
  h4{
    font-weight:700;
    text-align:center;
  }
`
const fields = [
  {
    label: "Full Name",
    name: "name",
    type: "text"
  },
  {
    label: "Company Name",
    name: "company",
    type: "text"
  },
  {
    label: "Industry",
    name: "industry",
    type:"lookup",
    values:[
      "Accounting",
      "Airlines/Aviation",
      "Alternative Dispute Resolution",
      "Alternative Medicine",
      "Animation",
      "Apparel & Fashion",
      "Architecture & Planning",
      "Arts & Crafts",
      "Automotive",
      "Aviation & Aerospace",
      "Banking",
      "Biotechnology",
      "Broadcast Media",
      "Building Materials",
      "Business Supplies & Equipment",
      "Capital Markets",
      "Chemicals",
      "Civic & Social Organization",
      "Civil Engineering",
      "Commercial Real Estate",
      "Computer & Network Security",
      "Computer Games",
      "Computer Hardware",
      "Computer Networking",
      "Computer Software",
      "Construction",
      "Consumer Electronics",
      "Consumer Goods",
      "Consumer Services",
      "Cosmetics",
      "Dairy",
      "Defense & Space",
      "Design",
      "Education Management",
      "E-learning",
      "Electrical & Electronic Manufacturing",
      "Entertainment",
      "Environmental Services",
      "Events Services",
      "Executive Office",
      "Facilities Services",
      "Farming",
      "Financial Services",
      "Fine Art",
      "Fishery",
      "Food & Beverages",
      "Food Production",
      "Fundraising",
      "Furniture",
      "Gambling & Casinos",
      "Glass, Ceramics & Concrete",
      "Government Administration",
      "Government Relations",
      "Graphic Design",
      "Health, Wellness & Fitness",
      "Higher Education",
      "Hospital & Health Care",
      "Hospitality",
      "Human Resources",
      "Import & Export",
      "Individual & Family Services",
      "Industrial Automation",
      "Information Services",
      "Information Technology & Services",
      "Insurance",
      "International Affairs",
      "International Trade & Development",
      "Internet",
      "Investment Banking/Venture",
      "Investment Management",
      "Judiciary",
      "Law Enforcement",
      "Law Practice",
      "Legal Services",
      "Legislative Office",
      "Leisure & Travel",
      "Libraries",
      "Logistics & Supply Chain",
      "Luxury Goods & Jewelry",
      "Machinery",
      "Management Consulting",
      "Maritime",
      "Marketing & Advertising",
      "Market Research",
      "Mechanical or Industrial Engineering",
      "Media Production",
      "Medical Device",
      "Medical Practice",
      "Mental Health Care",
      "Military",
      "Mining & Metals",
      "Motion Pictures & Film",
      "Museums & Institutions",
      "Music",
      "Nanotechnology",
      "Newspapers",
      "Nonprofit Organization Management",
      "Oil & Energy",
      "Online Publishing",
      "Outsourcing/Offshoring",
      "Package/Freight Delivery",
      "Packaging & Containers",
      "Paper & Forest Products",
      "Performing Arts",
      "Pharmaceuticals",
      "Philanthropy",
      "Photography",
      "Plastics",
      "Political Organization",
      "Primary/Secondary",
      "Printing",
      "Professional Training",
      "Program Development",
      "Public Policy",
      "Public Relations",
      "Public Safety",
      "Publishing",
      "Railroad Manufacture",
      "Ranching",
      "Real Estate",
      "Recreational",
      "Facilities & Services",
      "Religious Institutions",
      "Renewables & Environment",
      "Research",
      "Restaurants",
      "Retail",
      "Security & Investigations",
      "Semiconductors",
      "Shipbuilding",
      "Sporting Goods",
      "Sports",
      "Staffing & Recruiting",
      "Supermarkets",
      "Telecommunications",
      "Textiles",
      "Think Tanks",
      "Tobacco",
      "Translation & Localization",
      "Transportation/Trucking/Railroad",
      "Utilities",
      "Venture Capital",
      "Veterinary",
      "Warehousing",
      "Wholesale",
      "Wine & Spirits",
      "Wireless",
      "Writing & Editing",
      "Other"
    ]
  },
  {
    label: "Company Address",
    name: "address",
    type: "textarea"
  },
  
  {
    label: "Email",
    name: "email",
    type: "text"
  },
  {
    label: "Work Phone / Mobile No.",
    name: "phone",
    type: "text"
  },
  {
    label: "Password",
    name: "password",
    type: "password"
  },
  {
    label: "Confirm Password",
    name: "confirm_password",
    type: "password"
  },
];

const Success = (props) => {
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
              <MessageBox>
                  <h4>Selamat!</h4>
                  <p>Akun afiliasi anda telah berhasil dibuat. Silahkan login untuk memulai aktifasi akun anda!</p>
                  <p>Pastikan anda telah mengikuti Tes Shiokaya Premium sebelum dapat menggunakan layanan Program Afiliasi Shiokaya!</p>
                  <div>
                    <Button title={'Login'} onClick={()=>{
                      document.location="/login";
                    }} style={{margin:'0 auto'}}></Button>
                  </div>
              </MessageBox>
              
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </>
  );
}

const Register = (props) => {
  const {callAction, call_action} = props;
  
  const [fetching, toggleFetching] = useState(false);
  const [snackbar, toggleSnackbar] = useState(false);
  const [snackbarText, setSnackbar] = useState(null);
  const [success, toggleSuccess] = useState(false);

  const showMessage = (msg)=>{
    toggleSnackbar(true);
    setSnackbar(msg);
    setTimeout(()=>{
      toggleSnackbar(false);
    },5000);
  }
  const handleRegister = (payload) => {
    if(typeof payload === 'undefined') return;
    if(payload === null) return;
    if(payload.status === 1){
      toggleSuccess(true);
    }else{
      showMessage("Mohon maaf, tidak dapat memproses pendaftaran anda. Silahkan coba kembali!");
    }
  }
  
  useEffect(()=>{
    if(fetching && call_action.register !== null){
      toggleFetching(false);
      handleRegister(call_action.register);
    }
  },[
    call_action
  ]);


  if(success) return <Success/>;
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
              <h4>Pendaftaran Program Afiliasi Shiokaya</h4>
              {snackbar ? (
                <Snackbar>
                    {snackbarText}
                </Snackbar>
              ) : <p>Mohon isi formulir dibawah ini dengan lengkap!</p>}
             
              
              <ModuleForm
             
                moduleProps={{
                  fields}}
                submitText={"Daftar"}
                progress={fetching}
                onSubmit={(values) => {
                  if (
                    values.password.localeCompare(values.confirm_password) !== 0
                  ) {
                    showMessage("Password anda tidak sama!")
                  } else {

                    callAction(call_action,"register",{
                      endpoint:"/affiliates/register",
                      scenario:'post',
                      data: values
                    });
                    toggleFetching(true);
                  }
                }}
                onError={(invalids) => {
                  console.log("invalids:", invalids);
                }}
              />
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default Register;
