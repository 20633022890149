import React from "react";

import Navbar from "../../Parts/Navbar";
import Home from "../Home";
import Footer from "../../Parts/Footer";

const App = () => {
  return (
    <section>
      <Navbar />
      <Home />
      <Footer />
    </section>
  );
};

export default App;
