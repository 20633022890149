import React,{useState} from "react";

export default function LabelAndTextArea(props) {
  const { label, value, onChange, onBlur, hint } = props;
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        marginBottom: "10px",
      }}
    >
      <label
        style={{
         
          ...props.labelStyle,
          fontWeight:'bold'
        }}
      >
        {label}
      </label>
      <div>
      {hint ? ( <p
        dangerouslySetInnerHTML={{ __html: hint }}
        ></p>) : null}
       
      </div>
      <div>
        <textarea
          id="outlined-basic"
          style={{
            width: "100%"
          }}
          defaultValue={value}
          multiline
          margin="normal"
          variant="outlined"
          onChange={onChange}
          onBlur={onBlur}
          inputProps={{
            style: {
              paddingTop: 8,
              paddingBottom: 8
            }
          }}
        />
      </div>
    </div>
  );
}
