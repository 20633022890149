import React from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyle from "./styles/DashboardStyles";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Sidebar from "./common/Sidebar";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Footer from "./common/Footer";
import DashboardBreadcrumbs from "./common/DashboardBreadcrumbs";

import CallAction from "../redux/actions/CallAction";
import { Actions } from "../redux/reducers";
import { userHasAccess, hasValue } from "../libs/helper";
import LabelAndText from "./widgets/LabelAndText";
import LabelAndTextArea from "./widgets/LabelAndTextArea";
import StatsBox from "./widgets/StatsBox";
import Table from "../Parts/Widgets/Table";
import Button from "../Parts/Widgets/Button";
import accounting from "accounting";
class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      showSidebar: false,
      pendings: [],
      fetching: false,
      summary: {
        impressions: 0,
        clicks: 0,
        buys: 0,
        gross: 0,
        sales: 0,
        pendings: 0,
      },
      affiliate_status: -1,
      vouchers: [],
    };
    this.renderActivation = this.renderActivation.bind(this);
  }
  componentDidMount() {
    const { call_action, callAction } = this.props;
    window.addEventListener("resize", (event) => {
      /*this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });*/
    });
    callAction(call_action, "dashboard", {
      endpoint: "/affiliate/summary/" + localStorage.getItem("affiliate_id"),
      scenario: "get",
    });
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action.dashboard !== call_action.dashboard) {
      this.handleUpdate(call_action.dashboard);
    }
  }
  handleUpdate(payload) {
    if (!hasValue(payload)) return;
    if (payload.status === 1) {
      localStorage.getItem("affiliate_no", payload.affiliate_no);
    }
    this.setState({
      fetching: false,
      summary: payload.summary,
      vouchers: payload.vouchers ? payload.vouchers : [],
      affiliate_status: payload.affiliate_status,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  renderActivation() {
    const { classes, moduleProps, modules, history, getSummary } = this.props;
    const {
      pendings,
      confirm_approve,
      item,
      approve_progress,
      confirm_reject,
      reject_progress,
      snackbar,
      snackbarText,
      fetching,
      summary,
      vouchers,
      affiliate_status,
    } = this.state;
    return <div className={classes.root}>
      <TopLoggedInNav history={history} modules={modules} />
      <Grid container>
        <Grid item xs={12} md={12}>
          <div
            style={{
              width: "100%",
              marginTop: 80,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={12}>
                <DashboardBreadcrumbs />
              </Grid>
              <Grid item md={12}>
                <div style={{maxWidth:600,margin:'0 auto',minHeight:window.innerHeight, textAlign:'center'}}>
                    <h1>Selamat Datang!</h1>
                    <p>Untuk bisa menjadi bagian dari program afiliasi shiokaya, dan membantu banyak orang-orang menggunakan framework shiokaya ini, kamu perlu terlebih dahulu mengetahui shio kaya diri kamu, dan lebih dalam tentang shiokaya kamu sendiri.</p>.
                    <div style={{textAlign:'center', margin:'0 auto'}}>
                    <Button
                        title={"Join Disini"}
                        onClick={() => {
                          document.location=process.env.REACT_APP_BUY_URL + '/buy';
                        }}
                        style={{margin: '0 auto'}}
                      />      
                    </div>
                </div>

               
              </Grid>
              
            </Grid>
          </div>
        </Grid>
        
      </Grid>

      <Snackbar
        open={snackbar}
        message={snackbarText}
        autoHideDuration={6000}
      ></Snackbar>

      <Footer />
    </div>;
  }
  render() {
    const { classes, moduleProps, modules, history, getSummary } = this.props;
    const {
      pendings,
      confirm_approve,
      item,
      approve_progress,
      confirm_reject,
      reject_progress,
      snackbar,
      snackbarText,
      fetching,
      summary,
      vouchers,
      affiliate_status,
    } = this.state;
    if (fetching) return <p>Please wait...</p>;
    if (affiliate_status === 0) return this.renderActivation();
    return (
      <div className={classes.root}>
        <TopLoggedInNav history={history} modules={modules} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DashboardBreadcrumbs />
                </Grid>
                <Grid item md={12}>
                  
                  <h3>Affiliate No: {localStorage.getItem("affiliate_no")}</h3>
                </Grid>
                
                <Grid item md={3} xs={12}>
                  <StatsBox
                    text={"Sales"}
                    value={
                      summary.buys
                        ? summary.buys
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : 0
                    }
                  />
                </Grid>
                
                <Grid item md={3} xs={12}>
                  <StatsBox
                    text={"Impressions"}
                    value={summary.impressions ? summary.impressions : 0}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <StatsBox
                    text={"Clicks"}
                    value={summary.clicks ? summary.clicks : 0}
                  />
                </Grid>

                <Grid item md={3} xs={12}>
                  <StatsBox
                    text={"CTR"}
                    value={
                      summary.impressions > 0
                        ? (summary.clicks / summary.impressions) * 100 + "%"
                        : 0
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <StatsBox
                    text={"Conversion Rates"}
                    value={
                      summary.clicks > 0
                        ? (summary.buys / summary.clicks) * 100 + "%"
                        : 0
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <StatsBox
                    text={"Revenues"}
                    value={
                      summary.revenue > 0
                      ? (`Rp.${accounting.formatNumber(summary.revenue,0,'.')}`)
                        : 0
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                <StatsBox
                    text={"Withdrawals"}
                    value={
                      summary.withdrawn > 0
                      ? (`Rp.${accounting.formatNumber(summary.withdrawn,0,'.')}`)
                        : 0
                    }
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                <StatsBox
                    text={"Saldo"}
                    value={
                      summary.saldo > 0
                        ? (`Rp.${accounting.formatNumber(summary.saldo,0,'.')}`)
                        : 0
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: 15 }}>
              <h4>Your Vouchers</h4>
              <Table
                data={vouchers}
                moduleProps={{
                  fields: [
                    {
                      name: "created_at",
                      label: "Tanggal Pembelian",
                      type: "date",
                    },
                    {
                      name: "expired_time",
                      label: "Expired",
                      type: "date",
                    },
                    {
                      name: "nama",
                      label: "Voucher",
                      type: "text",
                    },
                    {
                      name: "code",
                      label: "Vouche Code",
                      type: "text",
                    },
                    {
                      name: "qty",
                      label: "Qty",
                      type: "number",
                    },
                    {
                      name: "total_redeem",
                      label: "Redeems",
                      type: "number",
                      render: (data) => {
                        return data.redeems.total;
                      },
                    },
                  ],
                }}
              />
              <div style={{maxWidth:200,marginBottom:60}}>
                <Button
                  title={"Buy Voucher"}
                  onClick={() => {
                    this.props.history.push("/buy");
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(DashboardContainer));
