import React from "react";
import styled from "styled-components";

const TrainningContent = styled.section`
h2 { 
  font-weight:700;
}
.registerButton {
  z-index: 10;
  background-color: #6d61ec;
  font-size: 18px;
  box-shadow: 1px 3px 5px grey;
}
  .container {
    margin-top: 50px;
  }
  .card,
  .body,
  .footer {
    background: #f8f8f5;
    margin-left: 20px;
    padding-left: 20px;
    padding-right: 10px;
  }

  h2 {
    margin-left: 0px;
  }
`;

const Trainning = () => {
  return (
    <TrainningContent>
      <div className="container" id="trainning">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="text-center">
              <h2 dangerouslySetInnerHTML={{__html:` Nantikan GRAND LAUNCHING Program Afiliasi ShioKaya&#8482;<br />9
                  January 2021`}}>
             
              </h2>
            
            </div>
            <div className="text-center">
              <h5 dangerouslySetInnerHTML={{__html:`Fitur-fitur yang termasuk didalam program afiliasi ShioKaya&#8482; ini antara lain:`}}></h5>
              
            </div>
            <div style={{maxWidth:800,margin:'0 auto'}}>
            <span dangerouslySetInnerHTML={{__html:`<p class="p1">&nbsp;</p>
<ol>
<li class="p2">Dokumentasi lengkap program afiliasi</li>
<li class="p2">Fitur program afiliasi (script, template landing page)</li>
<li class="p2">Skema pricing &amp; discount program afiliasi</li>
<li class="p2">Fitur program training (user, training for trainer)</li>
<li class="p2">Skema pricing program training</li>
<li class="p2">Marketing support</li>
<li class="p2">Dapatkan kesempatan pertama untuk bergabung di program afiliasi ShioKaya&#8482;, dan dapatkan KEUNTUNGAN sebagai mitra AFILIASI AWAL ShioKaya&#8482;<br /><br /></li>
</ol>

<p class="p1">&nbsp;</p>
<p class="p1">&nbsp;</p>

`}}>
                
              </span>
              <div class="text-center">
              <h4 class="p2">MARI BERGABUNG DARI SEKARANG BERSAMA KAMI!</h4>
              </div>
            </div>
            <div style={{marginBottom:60}}>
                <div style={{textAlign:'center'}}>
                <button className="btn btn-lg btn-round registerButton" style={{margin:'0 auto',maxWidth:300,}} onClick={()=>{
                   document.location="/register";
                }}>
                  Bergabung
                </button>
                  </div>
              </div>
          </div>
         
        </div>
      </div>
    </TrainningContent>
  );
};

export default Trainning;
