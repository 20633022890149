import { combineReducers } from "redux";
import CreateStore from "./CreateStore";
import CrudStore from "./CrudStore";
import crud from "./crud";
import crud_add from "./crud_add";
import crud_delete from "./crud_delete";
import crud_get from "./crud_get";
import crud_update from "./crud_update";
import do_action from "./do_action";
import call_action from "./call_action";

//auto-create CRUD actions / reducers
//const profile = CrudStore("profile", "/profile");
//const courses = CrudStore("courses", "/courses");

//auto create custom actions / reducers
const [login, loginAction] = CreateStore("LOGIN", "/login", "post");
const [register, registerAction] = CreateStore("REGISTER", "/register", "post");

//combine the reducers
export default combineReducers({
  crud,
  crud_add,
  crud_delete,
  crud_update,
  crud_get,
  do_action,
  call_action,
  login,
  register,
});

//setup list of actions
const Actions = {
  loginAction,
  registerAction,

};

export { Actions };
