import React, { useState, useEffect } from "react";
import LabelAndImagePicker from "./LabelAndImagePicker";
import styled from "styled-components";
import { FaPlusCircle } from "react-icons";

const MultiImageUploader = styled.section`
  .photos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    border-bottom: 1px solid #000000;
    padding-bottom: 30px;
  }
  .photos .item {
    margin-left: 5px;
    margin-right: 5px;
    width: 30%;
  }
  .photos. .item img {
    width: 100%;
  }
`;
export default function (props) {
  const { label, onChange, action } = props;
  const [photos, setPhotos] = useState(
    typeof props.photos !== "undefined" ? props.photos : []
  );
  const [update, setUpdate] = useState(0);
  useEffect(() => {
    if (photos.length > 0) {
      onChange(photos);
    }
  }, [update]);
  return (
    <MultiImageUploader>
      <label style={{fontWeight:'bold'}}>{label}</label>
      <div class={"photos"}>
        {photos.map((photo, idx) => {
          return (
            <div key={idx} className={"item"}>
              <img src={photo} />
            </div>
          );
        })}
      </div>
      <div>
        <LabelAndImagePicker
          action={action}
          value={null}
          preview={false}
          label={false}
          onChange={(file_uri, fileObj) => {
            let v = photos;
            if (fileObj) {
              let img = {
                original: fileObj.original.Location,
                hp_file: fileObj.hp.Location,
                thumbnail_file: fileObj.thumbnail.Location,
              };
              v.push(img);
            } else {
              v.push(file_uri);
            }
            setPhotos(v);
            setUpdate(update + 1);
          }}
        />
      </div>
    </MultiImageUploader>
  );
}
