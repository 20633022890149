import React from "react";
import styled from "styled-components";
import { Illustrasi } from "../Assets";

const ResellerContent = styled.section`
h2 { 
  font-weight:700;
}
.registerButton {
  z-index: 10;
  background-color: #6d61ec;
  font-size: 18px;
  box-shadow: 1px 3px 5px grey;
}
  .container {
    margin-top: 50px;
    h2{
      font-weight:700;
    }
    @media only screen and (max-width: 420px) {
      h2 {
        text-align: center;
      }
      p {
        text-align: justify;
      }
      p.title {
        font-size: 12px;
        text-align: left;
      }
    }
  }
`;

const Reseller = () => {
  return (
    <ResellerContent>
      <div className="container" id="reseller">
        <div className="row clearfix">
          <div className="col-lg-7">
            <div className="card">
            <h2 style={{fontWeight:700}} dangerouslySetInnerHTML={{__html:`Peluang Untuk Menjadi <br />Reseller ShioKaya&#8482;`}}></h2>
                <p dangerouslySetInnerHTML={{__html:`Untuk bergabung dalam kemitraan afiliasi Shiokaya, ada juga 5
                  langkah mudah yang bisa Anda lakukan. Pertama, pastikan bahwa
                  Anda sudah mengetahui Shiokaya secara detail, dengan cara
                  menjadi pelanggan, lihat di channel YouTube, di situs, atau di
                  IG Shiokaya. Kedua, Anda bergabung dengan program afiliasi
                  Shiokaya ini dengan meng-klik tautan dibawah. Ketiga, Anda
                  mengisi informasi yang dibutuhkan untuk kami bisa men-support
                  anda (nama, kontak, dll). Keempat, Anda memilih channel
                  pemasaran, beserta banner atau link yang anda butuhkan dari
                  halaman program afiliasi kami. Kelima, Anda memanfaatkan atau
                  mengembangkan jaringan Anda (situs, email, messaging, dll) dan
                  meningkatkan traffic Anda.
                `}}>
                  
                </p>
                <button type="button" className="registerButton btn btn-round" onClick={()=>{
                  document.location="/register";
               }}>
                  Bergabung
                </button>
            </div>
           
          </div>
          <div className="col-lg-5 col-sm-6">
            <img src={Illustrasi.url} alt={Illustrasi.alt} />
          </div>
        </div>
      </div>
    </ResellerContent>
  );
};

export default Reseller;
