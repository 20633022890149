import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.div`
    font-weight:700;
    font-family:'Gilround Bold', sans-serif;
    background-color:#6d61ec;
    padding:10px;
    cursor:pointer;
    border-radius:10px;
    text-align:center;
    color:white;
    width:100%;
    box-shadow: 1px 3px 5px grey;
    
    :hover{
        background:#000000;
        color:#ffffff;
    }
`

export default function (props) {
  const { onClick,title, icon, style} = props;

  return <Button style={style} href="#" onClick={onClick}>{typeof icon !== 'undefined' ? icon() : title}</Button>;
}
