import React, { useState, useEffect } from "react";

export default function ArrayLookup(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    values,
    id,
    label,
    hint,
    data,
    defaultValue,
    placeholder
  } = props;
  const [value, setValue] = useState("");
  const [update, setUpdate] = useState(0);
  const [sel, setSelected] = useState(0);
  useEffect(() => {
    console.log({'default': props.defaultValue});
    if(typeof props.defaultValue === 'function'){
      setValue(props.defaultValue(data));
    }else{
      setValue(props.defaultValue);
    }
    
  }, [props.defaultValue]);

  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
     {label &&  <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      </div>}
      {hint ? (
        <div style={{ marginBottom: 10 }}>
          <p dangerouslySetInnerHTML={{ __html: hint }}></p>
        </div>
      ) : null}
      <select
        style={{ padding: 10, width: "100%" }}
        value={value}
        onChange={(evt) => {
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
      >
        <option value={""}>{placeholder ? placeholder : `Silahkan Pilih`}</option>
        {values.map((item, index) => {
          let selected = null;

          if (typeof defaultValue === "function") {
            if (typeof item === "object") {
              if (defaultValue(data) === item.value) selected = "selected";
            } else {
              if (defaultValue(data) === item) selected = "selected";
            }
          }
         
          if (selected === 'selected') {
            return (
              <option
                key={id + "-" + index}
                value={typeof item === "object" ? item.value : item}
                selected={"selected"}
              >
                {typeof item === "object" ? item.text : item}
              </option>
            );
          } else {
            return (
              <option
                key={id + "-" + index}
                value={typeof item === "object" ? item.value : item}
              >
                {typeof item === "object" ? item.text : item}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
}
