import React, { useState, useEffect } from "react";
import Api from "../../Services/Api";

export default function ResourceLookup(props) {
  const {
    onChange,
    id,
    label,
    resource,
    field,
    stores,
    params,
    data,
    hint,
    defaultValue,
    placeholder,
  } = props;

  const api = new Api();

  const [value, setValue] = useState("");
  const [values, setValues] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    (() => {
      //retrieve lookup
      let endpoint =
        "/" + (typeof resource === "function" ? resource() : resource);

      if (field && typeof field.resource === "function") {
        endpoint = field.resource(null, stores);
      }

      api
        .crud({
          endpoint,
          actionType: "LOOKUP",
          data: typeof params !== "undefined" ? params : {},
        })
        .then((response) => {
          setValues(response.data);
          //setValue(value);
          setUpdate(update + 1);
        })
        .catch((err) => {
          console.log("resource loading error:", err);
          setValues([]);
        });
    })();
  }, [resource]);

  if (typeof values === "undefined") return "Loading...";

  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
     {label && <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      </div>}
      {hint ? (
        <div style={{ marginBottom: 10 }}>
          <p dangerouslySetInnerHTML={{ __html: hint }}></p>
        </div>
      ) : null}
      <select
        style={{ padding: 10, width: "100%" }}
        onChange={(evt) => {
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
      >
        <option value={""}>
          {placeholder ? placeholder : `Silahkan Pilih`}
        </option>
        {values.map((item, index) => {
          let selected = null;

          if (typeof defaultValue === "function") {
            /*if(typeof item === "object"){
              if(defaultValue(data) === item.value) selected="selected";
            }else{
              if(defaultValue(data) === item) selected="selected";
            }*/
            if (item[field.key] === defaultValue(data)) selected = "selected";
          }

          return (
            <option
              key={index}
              value={typeof item === "object" ? item[field.key] : item}
              selected={selected}
            >
              {field.value(item)}
            </option>
          );
        })}
      </select>
    </div>
  );
}
