import React from "react";
import { connect } from "react-redux";
import Navbar from "../../Parts/Navbar";
import Register from "./Register";
import Footer from "../../Parts/Footer";
import CallAction from "../../redux/actions/CallAction";

const RegisterForm = (props) => {
  return (
    <section>
      <Navbar />
      <Register {...props} />
      <Footer />
    </section>
  );
};

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
