import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import styled from "styled-components";
import Button from "./Button";
import Loading from "./Loading";
const TableDiv = styled.div`
  table {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  thead {
    background-color: #fae9d6;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  th {
    font-family: "Gilroy Bold";
    padding: 10px;
  }
  td {
    padding: 10px;
  }
  tr {
    border-bottom: 1px solid #cccccc !important;
  }
  tbody tr:hover {
    background-color: #e5e5e5;
  }
  tr:hover {
    background-color: #e5e5e5;
  }
  .responsiveTable tbody tr {
    border: #ffffff;
    padding: 0.25em;
    background-color: #fae9d6;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

const NoDataDiv = styled.div`
  padding: 60px 30px;
  border:1px solid #cccccc;
  margin-bottom:20px;
`;
const NoData = (props) => {
  return (
    <NoDataDiv>
      <p>the list is empty.</p>
    </NoDataDiv>
  );
};
const OnProgress = (props) => {
  return (
    <NoDataDiv>
      <Loading />
    </NoDataDiv>
  );
};
export default function (props) {
  const { data, moduleProps, onAction, fetching } = props;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [fields, setFields] = useState(
    moduleProps.fields ? moduleProps.fields : []
  );
  const [actions, setActions] = useState(
    moduleProps.actions ? moduleProps.actions : []
  );
  const [cells, setCell] = useState([]);

  useEffect(() => {
    let c = [];
    let f = [];
    fields.map((field) => {
      c.push(field.label);
      f.push(field.name);
      setColumns(c);
      setCell(f);
    });
  }, [moduleProps]);
  if (fetching) return <OnProgress />;
  if (typeof data === "undefined") return <NoData />;
  if (data === null) return <NoData />;
  if (data.length === 0) return <NoData />;
  if (!Array.isArray(data)) return <NoData />;
  return (
    <TableDiv>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column, idx) => {
              return <Th key={idx}>{column}</Th>;
            })}
            {actions.length > 0 ? <Th></Th> : null}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((d, idx) => {
            return (
              <Tr key={`tr${idx}`}>
                {cells.map((f, i) => {
                  return (
                    <Td key={`f${i}`}>
                      {typeof fields[i].render !== "undefined"
                        ? fields[i].render(d)
                        : d[f]}
                    </Td>
                  );
                })}
                {actions.length > 0 ? (
                  <Td>
                    {actions.map((action, idx) => {
                      return (
                        <Button
                          key={idx}
                          title={action.label}
                          icon={action.icon}
                          onClick={() => {
                            onAction(action.name, action, d);
                          }}
                        />
                      );
                    })}
                  </Td>
                ) : null}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableDiv>
  );
}
