import React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Snackbar,
  Collapse
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyle from "./styles/DashboardStyles";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Sidebar from "./common/Sidebar";

import Footer from "./common/Footer";
import DashboardBreadcrumbs from "./common/DashboardBreadcrumbs";

import CallAction from "../redux/actions/CallAction";
import { Actions } from "../redux/reducers";
import { userHasAccess } from "../libs/helper";
import LabelAndText from "./widgets/LabelAndText";
import LabelAndTextArea from "./widgets/LabelAndTextArea";
import StatsBox from "./widgets/StatsBox";

class SetupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",

      pendings: [],
      copySuccess: "",
      adcampaigns: [],
    };
    this.renderCampaigns = this.renderCampaigns.bind(this);
    this.handleCampaignLoaded = this.handleCampaignLoaded.bind(this);
  }
  componentDidMount() {
    const { call_action, callAction } = this.props;
    callAction(call_action, 'setup', {
      endpoint: '/adcampaigns',
      scenario:'get',
      data:{
        affiliate_id:localStorage.getItem("affiliate_id"),
      }
    })
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    /* if (_props.call_action.pending_request !== call_action.pending_request) {
      this.handlePendingRequests(call_action.pending_request);
    }*/
    if (_props.call_action.setup !== call_action.setup) {
      this.handleCampaignLoaded(call_action.setup);
    }
  }
  componentWillUnmount() {

  }
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");

    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    this.setState({
      snackbar: true,
      snackbarText: "the code has been copied to clipboard!"
    })
  };
  handleCampaignLoaded(payload) {
    if (typeof payload === 'undefined') return;
    if (payload === null) return;

    this.setState({
      adcampaigns: payload.adcampaigns
    })
  }
  renderCampaigns(campaign,key) {
    return (
      <Grid key={key} container spacing={2}>
        <Grid item xs={12}>
          <div style={{
            borderBottom: '1px solid #ccc',
            padding: 10,
            border:'1px solid #ccc'

          }}>
            <h3 style={{margin:0}}>{campaign.name}</h3>
            <h4 style={{margin:0}}>No. {campaign.campaign_id}</h4>
            <p>{campaign.description}</p>
          </div>
        </Grid>
        {campaign.assets.map((asset,index)=>{
          return (
            <Grid item xs={6} key={index}>
          <div style={{
            padding: 10,
            border: '1px solid #ccc',
            borderRadius: 10,
            textAlign: 'center',
            minHeight:466
          }}>
            <h1>{asset.name}</h1>
            <img src={asset.file} style={{maxWidth:300, maxHeight:300}} />
            <div>


              <textarea style={{ width: 400, height: 80 }}
                ref={(textarea) => (this.textArea = textarea)}
                value={asset.script}
              />
              {document.queryCommandSupported("copy") && (
                <div>
                  <button onClick={this.copyToClipboard}>Copy</button>

                </div>
              )}
            </div>
          </div>
        </Grid>
          );
        })}
        <Grid item xs={6}>
          <div style={{
            padding: 10,
            border: '1px solid #ccc',
            borderRadius: 10,
            textAlign: 'center',
            minHeight:466
          }}>
            <h1>SHARE LINK</h1>
           
            <div>


              <textarea style={{ width: 400, height: 80 }}
                ref={(textarea) => (this.textArea = textarea)}
                value={`<a href="${campaign.landing_url}">Shiokaya</a>`}
              />
              {document.queryCommandSupported("copy") && (
                <div>
                  <button onClick={this.copyToClipboard}>Copy</button>

                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>

    )
  }
  render() {
    const {
      classes,
      moduleProps,
      modules,
      history,
      getSummary,
      summary,
    } = this.props;

    const {
      pendings,
      confirm_approve,
      item,
      approve_progress,
      confirm_reject,
      reject_progress,
      adcampaigns,
      snackbar,
      snackbarText,
    } = this.state;
    return (
      <div className={classes.root}>
        <TopLoggedInNav
          history={history}

          modules={modules}
        />
        <Grid container>

          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DashboardBreadcrumbs />
                </Grid>
                <Grid item md={12}>
                  <h1>Setup</h1>
                </Grid>
                <Grid item md={12}>
                  <p>Silahkan pilih Campaign dibawah ini: </p>
                </Grid>
                <Grid item md={12}>
                  {adcampaigns.map((campaign, index) => {
                    return this.renderCampaigns(campaign, index)
                  })}
                </Grid>
                
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(SetupContainer));
