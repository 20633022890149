/**
 * reference: https://www.npmjs.com/package/reactjs-file-uploader
 */
import React, { useState } from "react";
import CircularProgress from "./CircularProgress";
import Api from "../../Services/Api";
const api = new Api();

export default function LabelAndFilePicker(props) {
  const { label, onChange, value, action, endpoint, filetypes, hint } = props;
  const [progress, setProgress] = useState(false);
  const [fileurl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState(null);

  return (
    <div>
      <div style={{padding:10,background:'#f5f5f5',marginBottom:30}}>
            <h4 style={{margin:0,marginBottom:5,fontSize: '100%',
    fontWeight: 700}}>{label}</h4>
            {progress ? (
                <CircularProgress/>
            ) : null}
            {file!==null ? (
                <div><a href={fileurl}>{file.name}</a></div>
            ) : null}
             
            <input
              type="file"
              name="file"
              accept={filetypes ? filetypes : `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
              text/plain, application/pdf, image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow`}
              onChange={event => {
               
                setFile(event.target.files[0]);
                setProgress(true);
                api
                  .upload(
                    "/media/upload",
                    {
                      file: event.target.files[0]
                    },
                    true,
                    endpoint
                  )
                  .then(response => {
                    setProgress(false);
                    setFileUrl(response.file_url);
                    onChange(response.file_url);
                  })
                  .catch(err => {
                    setProgress(false);
                    console.log(err.message);
                  });
              }}
            />
            {hint ? <p>{hint}</p> : <p>*) Dokumen PDF, Maksimal 2MB</p>}
          </div>
    </div>
  );
}
