import React from "react";
import ResponsiveMenu from "react-responsive-navbar";
import styled from "styled-components";
import { FaBars, FaRegWindowClose } from "react-icons/fa";

const Menu = styled.div`
  ul {
    padding: 0;
  }
  li {
    display: inline;
    font-size: 13px;
    list-style-type: none;
    margin-left: 30px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    color: MediumPurple;
    &:hover {
      color: white;
    }
  }
  @media (max-width: 500px) {
    padding: 10px 0;
    li {
      padding: 10px 0;
      display: block;
      margin-left: 0;
    }
  }
`;

export default function Top(props) {
  return (
    <ResponsiveMenu
      menuOpenButton={<FaBars size={30} color="black" />}
      menuCloseButton={<FaRegWindowClose size={30} color="black" />}
      changeMenuOn="500px"
      largeMenuClassName="large-menu"
      smallMenuClassName="small-menu"
      style={{
        zIndex:10,
        background:'white'
      }}
      menu={
        <Menu>
          <ul style={{margin:0}}>
            <li>
            <img style={{ height: 50 }} src={'https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/logo-shiokaya.png'} />
            </li>
          </ul>
        </Menu>
      }
    />
  );
}
