import React,{Suspense, lazy }  from "react";
import withAuth from "./withAuth";
import LoginContainer from "./Components/LoginContainer";
import LogoutContainer from "./Components/LogoutContainer";
import ModuleWrapper from "./ModuleWrapper";
import modules from "./modules";
//import LandingContainer from "./Components/LandingContainer";
const RegistrationContainer = React.lazy(() => import("./Components/RegistrationContainer"));

import Home from "./Page/App";
import Register from "./Page/Register";
import ModuleContainer from "./Components/ModuleContainer";

// const getModules = () => {
//   let routes = [];
//   return modules.map((item, index) => {
//     return {
//       path: "/" + item.slug,
//       exact: true,
//       component: item.crud
//         ? null
//         : withAuth(ModuleWrapper(item.component, item, modules)),
//     };
//   });
// };
const getModules = () => {
  let routes = [];
  return modules.map((item, index) => {
    return {
      path: "/" + item.slug,
      exact: true,
      component: item.crud
        ? withAuth(ModuleWrapper(ModuleContainer, item, modules))
        : withAuth(ModuleWrapper(item.component, item, modules))
    };
  });
};
const getModulesByName = (slug) => {
  for (let k in modules) {
    if (modules[k].slug === slug) return modules[k];
  }
};
const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
 {
    path: "/login",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/logout",
    exact: true,
    component: LoginContainer,
  },
  
  {
    path: "/register",
    exact: true,
    component: Register,
  },
  
  ...getModules(),
];

export default routes;
