export default {
    name: "Resellers",
    slug: "resellers",
    singularName: "Page",
    crud: true,
    endpoint: "/affiliates/resellers",
    updateLabel: "page",
    meta: {
      delete_flag: true,
    },
   
    delete: true,
    add: true,
    update: true,
    edit: true,
    access: ["admin","affiliate"],
    fields: [
    {
        label: "Nomor Afiliasi",
        name: "affiliate_no",
        type: "auto",
        input: false,
      },
      {
        label: "Nama Lengkap",
        name: "name",
        type: "text"
      },
     
      {
        label: "Alamat",
        name: "address",
        type: "textarea",
        visible:false,
      },
      
      {
        label: "Email",
        name: "email",
        type: "text"
      },
      {
        label: "No.Hp / Whatsapp",
        name: "phone",
        type: "text",
      },
      
      {
        label: "Password",
        name: "password",
        type: "password",
        visible:false,
      },
      {
        label: "Confirm Password",
        name: "confirm_password",
        type: "password",
        visible:false
      },
    ]
}