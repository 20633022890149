import React from "react";
import styled from "styled-components";
import { Satu, Dua, Tiga } from "../../Parts/Assets";

const AfiliasiContent = styled.section`
  h2{
    font-weight:700;
  }
  .card img{
    margin-bottom:20px;
  }
  .container {
    z-index: -10;
    background-color: #d7f9ff;
    margin-top: 100px;
    padding-top: 50px;
    @media only screen and (max-width: 420px) {
      text-align: center;
    }
  }
`;

const Afiliasi = () => {
  const [afiliasi] = React.useState([
    {
      id: 1,
      img: Satu,
      title: "Program Reseller ShioKaya&#8482;",
      subtitle: "sit amet consectetur",
      content:`Dapatkan diskon khusus dengan mempromosikan dan menjadi
      reseller assessment bisnis ShioKaya&#8482; di situs anda`,
    },
    {
      id: 2,
      img: Dua,
      title: "Peluang dari program edukasi ShioKaya&#8482;",
      subtitle: "sit amet consectetur",
      content:`Turut mempromosikan dan menjadi reseller untuk program
        pengenalan diri lebih jauh dengan online training ShioKaya&#8482;
        Awareness Program`,
    },
    {
      id: 3,
      img: Tiga,
      title: `Bisnis Coaching menggunakan ShioKaya&#8482;`,
      subtitle: "sit amet consectetur",
      content:`Mampu menjadi seorang coach/mentor/konsultan untuk membantu
      teman dan kenalan dalam membangun bisnisnya denga memanfaatkan
      Framework ShioKaya&#8482;`,
    },
  ]);
  return (
    <AfiliasiContent>
      <div className="container" id="afiliasi">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="text-center">
            <h2>Persiapan Program Afiliasi Shiokaya <br /></h2>
                <p dangerouslySetInnerHTML={{__html:`Peluang untuk menjadi reseller ShioKaya&#8482;<br />sekaligus masuk lebih dalam tentang filosofi, konsep,
                  metode, dan implementasi shiokaya<br />`}}>
                  

                  </p>
            </div>
          </div>
          {afiliasi.map((af) => (
            <div className="col-lg-4" key={af.id}>
              <div className="card">
                <img
                  src={af.img.url}
                  alt={af.img.alt}
                  width="100px"
                  height="auto"
                />
                <p>
                  <b dangerouslySetInnerHTML={{__html:af.title}}>
                 
                  </b>
                </p>
                <p dangerouslySetInnerHTML={{__html:af.content}}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AfiliasiContent>
  );
};

export default Afiliasi;
