import React from "react";
import styled from "styled-components";
import { Line, Banner } from "../Assets";

const Head = styled.section`
margin-top:80px;
  h2 {
    padding: 50px 0;
  }
  .registerButton {
    z-index: 10;
    background-color: #6d61ec;
    font-size: 18px;
    box-shadow: 1px 3px 5px grey;
  }
  span {
    font-size: 15px;
  }
  .banner {
    position: absolute;
    width: auto;
    height: auto;
    z-index: -10;
    margin-left: -40px !important;
  }
  img {
    position: absolute;
    margin-top: -35px;
    margin-left: -180px;
    width: 150px;
    height: 250px;
    z-index: 1;
  }
  @media only screen and (max-width: 420px) {
    .banner {
      margin-top: -150px;
      height: auto;
      z-index: -10;
    }
    img {
      position: absolute;
      margin-top: 75px;
      margin-left: -150px;
      width: 120px;
      height: 150px;
      z-index: 1;
    }
    .registerButton {
      z-index: 10;
      background-color: #6d61ec;
     
      font-size: 10px;
    }
    h2 {
      padding: 0px 0;
    }
  }
`;

const Header = (props) => {
  return (
    <Head>
      <div className="container mt-4">
        <div className="row clearfix">
          <div className="col-lg-4">
            <div className="card py-auto">
                <h2 style={{margin: 0, padding: 0,marginTop:4,fontWeight:700}}>Selamat Datang</h2>
                <h4 style={{margin: 0, fontSize: '120%',marginBottom:20}} dangerouslySetInnerHTML={{__html:`di halaman persiapan afiliasi ShioKaya`}}>
                  
                </h4>
                <p>
                  Menjadi Mitra ShioKaya untuk kemajuan diri pribadi<br />dan
                  membangun potensi Pendapatan
                  Tambahan untuk anda.
                </p>
               
                 
               <div style={{marginBottom:160}}>
               <button type="button" className="registerButton btn btn-round" onClick={()=>{
                  document.location="/register";
               }}>
                  Bergabung
                </button></div>
              
              <img src={Line.url} alt={Line.alt} />
            </div>
          </div>
          <div className="col-lg-8">
            <img src={Banner.url} alt={Banner.alt} className="banner" />
          </div>
        </div>
      </div>
    </Head>
  );
};

export default Header;

// 3951 x 2757 , kiri : 1448 x 1140, kanan : 1448 x 1140
