import React from "react";
import NumberFormat from "react-number-format";

export default function LabelAndNumberInput(props) {
  const { label, onChange, value, hint } = props;
  return (
    <div
      style={{
        marginBottom: "10px",
        ...props.containerStyle
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          ...props.labelStyle
        }}
      >
        {label}
      </div>
      {hint ? ( <div>    <p
        dangerouslySetInnerHTML={{ __html: hint }}
        ></p></div>) : null}
     
      <div style={{
          marginTop:10,
      }}>
        <NumberFormat
          value={value ? value : 0}
          style={{
            padding: '6px 10px',
            fontSize: '100%',
            borderRadius: 4,
            width:'100%',
            border: '1px solid rgb(204, 204, 204)',
            marginTop: 10
          }}
          displayType={"input"}
          thousandSeparator={true}
          onValueChange={value => {
            onChange(value.value);
          }}
        />
      </div>
    </div>
  );
}
