import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {  Actions } from "../redux/reducers";
//import LoginAction from "../redux/actions/login";
import LoginContainerStyles from "./styles/LoginContainerStyles";
import Top from "./common/Top";
import Footer from "./common/Footer";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: ""
    };
  }
  componentDidMount() {
    localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { login } = this.props;
    if (_props.login !== login) {
      if (_props.login.fetching !== login.fetching) {
        this.setState({
          fetching: login.fetching
        });
      }
      if (_props.login.payload !== login.payload && login.payload !== null) {
        
        if (typeof login.payload !== "undefined") {
          if(login.payload.status !== 1) return this.setState({
            snackbar: true,
            snackbarText: "Username dan Password yang anda masukkan salah !"
          });

          if(!login.payload.roles.includes('affiliate')){
            this.setState({
              snackbar: true,
              snackbarText: "Username dan Password yang anda masukkan salah !"
            });
            return;
            
          }
          if(login.payload.affiliate.id === 0){
            this.setState({
              snackbar: true,
              snackbarText: "Username dan Password yang anda masukkan salah !"
            });
            return;
          }
          localStorage.setItem("token", login.payload.access_token);
          localStorage.setItem("roles", login.payload.roles);
          localStorage.setItem("user_id", login.payload.user.id);
          localStorage.setItem("affiliate_no", login.payload.affiliate.affiliate_no);
          localStorage.setItem("affiliate_id", login.payload.affiliate.id);
       //   localStorage.setItem("session_id", login.payload.user.session_id);
          localStorage.setItem("name", login.payload.user.name);
        //  localStorage.setItem("gid", login.payload.gid);
         // localStorage.setItem("mdid", login.payload.mdid);
          //localStorage.setItem("oid", login.payload.oid);
         
          this.setState({ success: true });
        }
      }
      if (_props.login.error !== login.error) {
        if (login.error) {
          this.setState({
            snackbar: true,
            snackbarText: "Username dan Password yang anda masukkan salah !"
          });
        }
      }
    }
  }
  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      fetching,
      success,
      session_id,
      snackbar,
      snackbarText
    } = this.state;

    if (success)
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { auth: true }
          }}
        />
      );

    return (
      <div className={classes.root}>
        
        <Top/>
        <Container className={classes.loginForm} style={{minHeight:window.innerHeight * 0.8}}>
          <h3>Silahkan Login</h3>
          <Grid xs={12}>
            <TextField
              label="E-Mail"
              fullWidth
              variant="filled"
              placeholder="Masukan Email akun anda..."
              style={{ marginBottom: 30 }}
              onChange={evt => this.setState({ email: evt.target.value })}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Password"
              type="password"
              variant="filled"
              fullWidth
              placeholder="Masukan password anda..."
              onChange={evt => this.setState({ password: evt.target.value })}
            />
          </Grid>
          <Grid xs={12} className={classes.action}>
            {!fetching ? (
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  this.props.doLogin({
                    email,
                    password
                  });
                }}
              >
                LOGIN
              </Button>
            ) : (
              <CircularProgress />
            )}
            <p>Belum punya akun ? <a href="/register">Daftar disini</a></p>
          </Grid>
        </Container>
        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
       <Footer/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { login } = state;
  return {
    login
  };
};

const mapDispatchToProps = dispatch => ({
  doLogin: data => dispatch(Actions.loginAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(LoginContainer));
