import React from "react";
export default function(props){
    const {label,value} = props;
    return (
        <div style={{
            padding:10
        }}>
            <div style={{
                padding:10,
                border:'1px solid #F9F8FC',
               
                textAlign:'left',
                minHeight:100,
                borderRadius:4,
                background:'#F9F8FC'
            }}>
                <h4 style={{
                    margin:0,
                    marginTop:10,
                    fontWeight:500
                }}>{label}</h4>
                <h3 style={{margin:0,marginTop:10,fontWeight:700}}>{value}</h3>
            </div>
        </div>
    )
}