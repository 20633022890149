import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {  Actions } from "../redux/reducers";
//import LoginAction from "../redux/actions/login";
import LoginContainerStyles from "./styles/LoginContainerStyles";
import Top from "./common/Top";
import Footer from "./common/Footer";

class LogoutContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: ""
    };
  }
  componentDidMount() {
    localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
   
  }
  render() {
   this.props.history.push("/login");
   return null;
  }
}

const mapStateToProps = state => {
  const { login } = state;
  return {
    login
  };
};

const mapDispatchToProps = dispatch => ({
  doLogin: data => dispatch(Actions.loginAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(LogoutContainer));
