import React from "react";
export default function LabelAndTextInput(props) {
  const {
    label,
    onChange,
    value,
    placeholder,
    hint,
    password,
    onEnterKey,
    
  } = props;
  return (
    <div style={{marginBottom:10}}>
      <label htmlFor={label} style={{fontWeight:'bold'}}>{label}</label>
      <input
        id={label}
        name={label}
        className="form-control"
        placeholder={placeholder}
        type={password ? "password": "text" }
        onBlur={(evt)=>{
          onChange(evt.target.value);
        }}
       
        
        defaultValue={value}
      />
    </div>
  );
  
}
