const text = (name, content) => {
  if (typeof content === "undefined") return;
  if (typeof content.payload === "undefined") return;
  if (content.payload === null) return;
  if (typeof content.payload.data === "undefined") return;

  let data = content.payload.data;

  for (let k in data) {
    if (data[k].name.localeCompare(name) === 0) return data[k].txt;
  }
};
const showMessage = (instance, text) => {
  instance.setState({
    snackbar:true,
    snackbarText: text
  });
}
const mustHaveValue = (obj) => {
  if(typeof obj === 'undefined') return;
  if(obj === null) return;
  return obj;
}
const hasValue = (obj) => {
  if(typeof obj === 'undefined') return;
  if(obj === null) return;
  return true;
}
const getValues = (props, key) => {
  if (typeof props === "undefined") return [];
  if (typeof props.payload === "undefined") return [];
  if (props.payload === null) return [];
  if (props.payload[key] === null) return [];
  return props.payload[key];
};

const getPayload = props => {
  if (typeof props === "undefined") return;
  if (typeof props.payload === "undefined") return;
  if (props.payload === null) return;

  return props.payload;
};

const userHasAccess = access => {
  let roles = localStorage.getItem("roles");
  
  if (!roles) return;
  if (roles.length === 0) return;
  for (let k in access) {
    if (roles.includes(access[k])) return true;
  }
  return false;
};

export {
  text,
  getValues,
  getPayload,
  userHasAccess,
  mustHaveValue,
  showMessage,
  hasValue
};

export default {
    text,
  getValues,
  getPayload,
  userHasAccess,
  mustHaveValue,
  showMessage
}