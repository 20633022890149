import React, { useState, useEffect } from "react";

import ResourceLookup from "./ResourceLookup";
import LabelAndTextInput from "./LabelAndTextInput";
import Button from "./Button";
import Api from "../../Services/Api";
import { FaTrash } from "react-icons/fa";
export default function MultiSelector(props) {
  const {
    onChange,
    values,
    id,
    label,
    hint,

    field,
    stores,
    customData,
    limit,
    data,
    initialValue,
  } = props;

  const api = new Api();

  const [value, setValue] = useState(props.value);
  const [selections, setValues] = useState([]);
  const [update, setUpdate] = useState(0);
  const [lainnya, setLainnya] = useState(false);
  const [custom, setCustom] = useState(false);
  const [textCustom, setTextCustom] = useState(null);
  useEffect(() => {
    setValue(props.value);

    if (typeof initialValue === "function") {
      if (typeof props.data !== "undefined") {
        let r = initialValue(props.data);
        setValues(initialValue(props.data));
      }
    }
  }, [props.value]);
  useEffect(() => {
    if (custom) {
      //retrieve lookup

      let endpoint =
        typeof field.newEntryResource === "function"
          ? field.newEntryResource()
          : field.newEntryResource;

      let data = {};
      if(typeof field.otherInput !== 'undefined'){
        field.otherInput.map((item)=>{
          data[item.name] = textCustom[item.name];
        });
      }else{
        data[field.labelKey] = textCustom;
      }
      
      if (typeof field.params !== "undefined") {
        data = { ...data, ...field.params };
      }
      api
        .crud({
          endpoint,
          actionType: "ADD",
          data,
        })
        .then((response) => {
          let node =
            typeof field.resourceNode !== "undefined"
              ? field.resourceNode
              : "data";

          if (typeof response[node] !== "undefined") {
            let vals = selections;
            vals.push(response[node]);
            setValues(vals);
            setCustom(false);
            setLainnya(false);
            onChange(vals);
            setUpdate(update + 1);
          }
          //setValue(value);
        })
        .catch((err) => {
          setCustom(false);
          setLainnya(false);
        });
    }
  }, [custom]);

  return (
    <div style={{ marginTop: 15, marginBottom: 15 }}>
      {label ? (
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {label}
        </div>
      ) : null}
      {selections.length > 0 ? (
        <div>
          {selections.map((item, idx) => {
            return (
              <div
               key={idx}
                id={idx}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#cccccc",
                  color: "#000000",
                  padding: 10,
                  borderRadius: 4,
                  marginBottom: 2,
                }}
              >
                <span style={{ flexGrow: 2, marginRight: 4 }}>
                  {item[field.labelKey]}
                </span>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let arr = selections;

                    let new_arr = [];
                    for (let k in arr) {
                      if (
                        parseInt(arr[k][field.key]) !==
                        parseInt(item[field.key])
                      ) {
                        new_arr.push(arr[k]);
                      }
                    }
                    setValues(new_arr);
                    setUpdate(update + 1);
                    onChange(new_arr);
                  }}
                >
                  <FaTrash />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
      {selections.length < parseInt(limit) ? (
        <div>
          {lainnya ? (
            <div style={{ border: "1px solid #ccc", padding: 10 }}>
              {typeof field.otherInput === 'undefined' ? <LabelAndTextInput
                label={"Lainnya"}
                hint={field.otherText ? field.otherText : ""}
                onEnterKey={(e) => {
                  setCustom(true);
                  setTextCustom(e.target.value);
                }}
                onChange={(evt) => {
                  //do nothing yet
                }}
              /> : (
                <div>
                  {field.otherInput.map((f,i)=>{
                    return  <LabelAndTextInput
                    key={`ti${i}`}
                    label={f.label}
                    onChange={(text) => {
                      //setTextCustom(evt.target.value);
                      let t = textCustom;
                      if(t == null){
                        t = {};
                      }
                      if(typeof t !== 'object'){
                        t = {};
                      }
                      t[f.name] = text;
                      //console.log("berubah nih",textCustom,evt.target.value, t);
                      setTextCustom(t);
                      setUpdate(update + 1);
                    }}
                  />
                  })}
                  <Button title={'Tambah'} variant={'contained'} onClick={()=>{
                   // console.log({textCustom})
                    setCustom(true);
                  }}></Button>
                </div>
              )}
              <Button
              title={'Batal'}
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                  setLainnya(false);
                }}
              >
                
              </Button>
            </div>
          ) : (
            <ResourceLookup
              resource={
                typeof field.resource === "function"
                  ? field.resource(customData, stores)
                  : field.resource
              }
              params={field.params}
              id={field.name}
              label={false}
              values={field.values}
              field={field}
              hint={field.hint}
              value={field.value}
              stores={stores}
              freeinput={typeof field.otherText !== 'undefined' ? field.otherText : false}
              onChange={(val) => {
                if (val === "-1") {
                  setLainnya(true);
                } else if (val === "") {
                  //do nothing
                } else {
                  let arr = selections;
                  console.log({'selections':selections})
                  if(typeof arr === 'undefined') arr = [];
                  if(typeof arr === 'string') arr = [];
                  if(arr === null) arr = [];
                  arr.push(val);
                  setValues(arr);
                  setUpdate(update + 1);
                  onChange(arr);
                }
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}
