const DashboardStyles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  action: {
    textAlign: "right"
  },
  topMargin: {
    marginTop: "30px"
  },
  filterButton: {
    marginTop: "20px"
  },
  appbar: {
    backgroundColor: "#fff"
  },
  logo: {
    width: "100px"
  },
  menubar: {
    listStyle: "none",
    padding: 0,
    margin: 0
  },
  menubarItem: {
    display: "inline-block",
    padding: "10px 30px"
  },
  menuBarItemText: {
    textDecoration: "none",
    color: "#333"
  },
  menubarItemActive: {
    display: "inline-block",
    padding: "10px 30px",

    backgroundColor: "#74b9ff"
  },
  menuBarItemTextActive: {
    textDecoration: "none",
    color: "#fff !important",
    fontWeight: "bold"
  },
  toolbar: {
    minHeight: 10
  },
  topActionButtons: {
    marginTop: 20,
    marginBottom: 29,
    flex: 1,
    alignSelf: "flex-end",
    textAlign: "right"
  },
  topDesc: {
    flex: 2,
    marginTop: 20,
    marginBottom: 29
  }
});

export default DashboardStyles;
