import React from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DashboardStyle from "./styles/DashboardStyles";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Footer from "./common/Footer";
import DashboardBreadcrumbs from "./common/DashboardBreadcrumbs";
import CallAction from "../redux/actions/CallAction";
import { userHasAccess, hasValue } from "../libs/helper";
import Table from "../Parts/Widgets/Table";
import accounting from "accounting";
import moment from "moment";

class WithdrawalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      showSidebar: false,
      pendings: [],
      qty: 0,
      fetching: false,

      data: [],
      pos: "listing",
      item: null,
    };
    this.Listing = this.Listing.bind(this);
  }
  componentDidMount() {
    const { call_action, callAction } = this.props;
    window.addEventListener("resize", (event) => {
      /*this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });*/
    });

    callAction(call_action, "dashboard", {
      endpoint: "/affiliates/withdraw",
      scenario: "get",
    });
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action.dashboard !== call_action.dashboard) {
      this.handleUpdate(call_action.dashboard);
    }
  }
  handleUpdate(payload) {
    if (!hasValue(payload)) return;

    this.setState({
      fetching: false,
      data: payload.withdraw ? payload.withdraw : [],
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  Listing({ fetching, data }) {
    return (
      <div style={{ padding: 15 }}>
        <Table
          progress={fetching}
          data={data}
          onAction={(name, action, item) => {}}
          moduleProps={{
            actions: [],
            fields: [
              {
                name: "created_at",
                label: "Date",
                type: "date",
                render: (row) => {
                  return moment(row.created_at).format("DD/MM/YYYY");
                },
              },

              {
                name: "amount",
                label: "Revenue",
                type: "number",
                render: (row) => {
                  return `Rp. ${accounting.formatNumber(row.amount, 0, ".")}`;
                },
              },
              {
                name: "withdraw_status",
                label: "Status",
                type: "text",
                render: (row) => {
                  let status = [
                    "pending",
                    "success",
                    "rejected",
                    "rejected",
                    "rejected",
                  ];
                  return status[parseInt(row.withdraw_status)];
                },
              },
            ],
          }}
        />
      </div>
    );
  }

  render() {
    const { classes, moduleProps, modules, history, getSummary } = this.props;
    const {
      snackbar,
      snackbarText,
      fetching,
      vouchers,
      pos,
      data,
    } = this.state;
    if (fetching) return <p>Please wait...</p>;
    return (
      <div className={classes.root} style={{minHeight:window.innerHeight*0.7}}>
        <TopLoggedInNav history={history} modules={modules} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DashboardBreadcrumbs />
                </Grid>
                <Grid item md={12}>
                  <h1>Withdrawal History</h1>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            {pos === "listing" ? (
              <this.Listing fetching={fetching} data={data} />
            ) : null}
          </Grid>
        </Grid>

        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(WithdrawalContainer));
