import React from "react";
import Header from "../../Parts/Header";
import Afiliasi from "../../Parts/Afiliasi";
import Reseller from "../../Parts/Reseller";
import Trainning from "../../Parts/Trainning";

const Home = () => {
  return (
    <>
      <Header />
      <Afiliasi />
      <Reseller />
      <Trainning />
    </>
  );
};

export default Home;
