import logo from "./img/LOGO SHIO KAYA-1.png";
import line from "./img/Line Illustration.png";
import banner from "./img/Untitled-1.png";
import satu from "./img/1.png";
import dua from "./img/2.png";
import tiga from "./img/3.png";
import illustration2 from "./img/Illustration 2.png";
import instragram from "./img/Instagram.png";
import email from "./img/Email.png";
import whatasapp from "./img/Whatsapp.png";

export const Instagram = {
  url: instragram,
  alt: "instagram",
};

export const Email = {
  url: email,
  alt: "email",
};

export const Whatsapp = {
  url: whatasapp,
  alt: "Whatsapp",
};

export const Illustrasi = {
  url: illustration2,
  alt: "illustration 2",
};

export const Tiga = {
  url: tiga,
  alt: "tiga",
};
export const Dua = {
  url: dua,
  alt: "dua",
};
export const Satu = {
  url: satu,
  alt: "Satu",
};

export const Banner = {
  url: banner,
  alt: "Banner",
};

export const Line = {
  url: line,
  alt: "line illustration",
};

export const Logo = {
  url: logo,
  alt: "logo shio kaya",
};
