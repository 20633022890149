import React, { useState, lazy, Suspense, useEffect } from "react";

import Button from "./widgets/Button";
import LabelAndText from "./widgets/LabelAndText";
import LabelAndTextInput from "./widgets/LabelAndTextInput";

import LabelAndNumberInput from "./widgets/LabelAndNumberInput";
import LabelAndTextArea from "./widgets/LabelAndTextArea";
import ArrayLookup from "./widgets/ArrayLookup";
import ResourceLookup from "./widgets/ResourceLookup";
import ResourceSearchableLookup from "./widgets/ResourceSearchableLookup";
import "rc-datetime-picker/dist/picker.min.css";
import { DatetimePickerTrigger } from "rc-datetime-picker";
//@TODO dropdown pilih dokter
//@TODO dropdown pilih nomor gigi
import unslug from "../libs/unslug";
import moment from "moment";
import LabelAndImagePicker from "./widgets/LabelAndImagePicker";
import LabelAndFilePicker from "./widgets/LabelAndFilePicker";
import LabelAndVideoPicker from "./widgets/LabelAndVideoPicker";
import LabelAndRadio from "./widgets/LabelAndRadio";
import MultiSelector from "./widgets/MultiSelector";
import { userHasAccess } from "../libs/helper";

import validator from "validator";
import LabelAndCheckbox from "./widgets/LabelAndCheckbox";
import styled from "styled-components";

var wordCount = require("html-word-count");

const SimpleCard = styled.div`
	padding:10px;
`
const getAllowedValues = (values, fields, data) => {
  let v = {};
  //v = Object.assign(v, values);

  fields.map((field) => {
    if (typeof field.access !== "undefined") {
      if (!userHasAccess(field.access)) return;
    }
    v[field.name] = values ? values[field.name] : null;
  });
  return v;
};
const addDefaultRadioValue = (values, fields, data) => {
  let v = {};
  v = Object.assign(v, values);
  fields.map((field) => {
    if (field.type === "radio") {
      if (typeof v[field.name] === "undefined") {
        if (typeof field.initialValue !== "undefined") {
          v[field.name] = field.initialValue;
        }
      }
      if (v[field.name] === null) {
        if (typeof field.initialValue !== "undefined") {
          v[field.name] = field.initialValue;
        }
      }
    }
  });
  return v;
};
const getAutoValues = (values, fields, data) => {
  let v = {};
  v = Object.assign(v, values);

  fields.map((field) => {
    if (field.type === "auto") {
      if (typeof field.access !== "undefined") {
        if (!userHasAccess(field.access)) return;
      }
      if (typeof field.input !== "undefined") {
        if (!field.input) return;
      }

      if (typeof data !== "undefined" && data !== null) {
        //jika data tersedia...
        let _val =
          typeof field.value === "function" ? field.value(data) : field.value;
        if (_val !== null) v[field.name] = _val;

        console.log("tersedia", { _val }, { data }, v);
      } else {
        if (typeof field.value !== "undefined") {
          if (field.value !== null && field.value !== "null")
            v[field.name] =
              typeof field.value === "function"
                ? field.value(data)
                : field.value;
        }
      }
    }
  });
  return v;
};
const skipAutofields = (values, fields) => {
  let v = {};
  fields.map((field) => {
    if (field.type !== "auto") v[field.name] = values[field.name];
  });
  return v;
};
const defaultValues = (values, fields, data) => {
  let v = {};
  v = Object.assign(v, values);

  fields.map((field) => {
    if (typeof field.defaultValue !== "undefined") {
      if (typeof v[field.name] === "undefined") {
        v[field.name] =
          typeof field.defaultValue === "function"
            ? field.defaultValue(data)
            : field.defaultValue;
      }
    }
  });
  return v;
};
const fixDates = (values, fields) => {
  let v = {};
  v = Object.assign(v, values);
  fields.map((field) => {
    if (field.type === "datetime") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD hh:mm:ss");
    } else if (field.type === "date") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD");
    }
  });
  return v;
};
const checkMandatories = (values, fields) => {
  let invalids = [];
  fields.map((field) => {
    let hasValue = true;

    if (typeof field.required !== "undefined" && field.required === true) {
      if (typeof values[field.name] === "undefined") hasValue = false;
      if (values[field.name] === null) hasValue = false;
      if (
        typeof values[field.name] === "string" &&
        values[field.name].length === 0
      )
        hasValue = false;
    }
    if (!hasValue) {
      invalids.push(field.label);
    }
  });

  return invalids;

  //return isValid;
};

const checkWordCounts = (values, fields, wordcount) => {
  let invalids = [];
  fields.map((field) => {
    let hasValue = true;

    if (typeof field.word_limit !== "undefined" && field.type === "richtext") {
      if (typeof wordcount[field.name] === "undefined")
        wordcount[field.name] = 0;
      if (wordcount[field.name] === null) wordcount[field.name] = 0;

      let limit = parseInt(field.word_limit);

      if (wordcount[field.name] < limit) {
        hasValue = false;
      }
    }
    if (!hasValue) {
      invalids.push(field.label);
    }
  });

  return invalids;

  //return isValid;
};

const triggered = (field, store) => {
  if (typeof field.triggerAfter !== "undefined") {
    let triggerVal = store[field.triggerAfter];

    if (typeof triggerVal === "undefined") return;
    if (triggerVal === null) return;

    //after we read the value from previous lookup
    //then we can check if these field require specific trigger condition
    //like if one lookup should have these value, and another lookup field must contain specific value
    //before allowing these lookup field to be displayed.
    if (typeof field.triggerCondition !== "undefined") {
      // console.log("pass condition : ", field.triggerCondition(store), store);
      if (!field.triggerCondition(store)) return;
    }
  }
  return true;
};
export default function ModuleForm(props) {
  const {
    open,
    onSubmit,
    onClose,
    onCancel,
    moduleProps,
    upload,
    uploadAction,
    key,
    data,
    onError,
    submitText,
    cancelText,
    progress,
    customData,
    onWordcountError,
    title,
  } = props;

  const [update, setUpdate] = useState(0);
  const [values, setValues] = useState(typeof data !== "undefined" ? data : {});
  const [store, setStore] = useState({});
  const [wordcount, setWordcount] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const fields =
    typeof moduleProps.fields !== "undefined" ? moduleProps.fields : [];

  useEffect(() => {
    if (data) {
      let new_data = {};
      for (let i in moduleProps.fields) {
        if (typeof moduleProps.fields[i].name !== "undefined") {
          let fieldName = moduleProps.fields[i].name.trim();
          if (data[fieldName] !== null) {
            new_data[fieldName] =
              typeof data[fieldName] === "object"
                ? data[fieldName].value
                : data[fieldName];
          }
        }
      }
      console.log("new_data:", new_data);
      setValues(new_data);
      setStore(data);
    }
  }, [data]);
  useEffect(() => {
    if (update === 0) {
      fields.map((field) => {
        if (typeof field.validation !== "undefined") {
          let f = fieldErrors;
          f[field.name] = false;
          setFieldErrors(f);
          setUpdate(1);
        }
      });
    }
  }, [moduleProps]);

  const checkValidationRules = (rule, value, values) => {
    if (typeof value === "undefined") return true;
    if (value === null) return true;
    let optionVal = rule.split(":");

    let check = rule;
    if (optionVal.length > 1) {
      check = optionVal[0];
    }
    switch (check) {
      case "mandatory":
        if (value.toString().length === 0) return true;
        break;
      case "email":
        if (!validator.isEmail(value.toString())) return true;
        break;
      case "alpha":
        if (!validator.isAlpha(value.toString())) return true;
        break;
      case "alphabet":
        if (!validator.isAlpha(value.toString())) return true;
        break;
      case "phone":
        if (!validator.isMobilePhone(value.toString())) return true;
        break;
      case "match":
        if (value.toString().localeCompare(values[optionVal[1].toString()]))
          return true;
        break;
      case "min":
        if (value.toString().length < parseInt(optionVal[1])) return true;
        break;
      default:
        return true;
        break;
    }
    return false;
  };
  const checkValidationErrors = (values, fields) => {
    let invalids = [];
    let f = fieldErrors;

    fields.map((field) => {
      if (typeof field.validation !== "undefined") {
        let rules = field.validation.split("|");
        for (let i = 0; i < rules.length; i++) {
          f[field.name] = checkValidationRules(
            rules[0],
            values[field.name],
            values
          );
          if (f[field.name] === true) {
            invalids.push(field.label);
          }
        }
      }
    });

    setFieldErrors(f);
    setUpdate(update + 1);
    return invalids;
  };
  const countTheWord = (txt) => {
    if (txt === null) return 0;
    if (txt.length === 0) return 0;
    let words = txt.replace(/<[^>]*>|\s/g, " ").split(" ");
    let wc = 0;
    words.map((w) => {
      if (w !== "" && w !== "&nbsp;") {
        wc++;
      }
    });
    return wc;
  };
  const renderField = (field, index) => {
    if (typeof field.input !== "undefined") {
      if (!field.input) return null;
    }
    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return null;
      }
    }
    if (typeof field.triggerAfter !== "undefined") {
      let triggerVal = store[field.triggerAfter];

      if (typeof triggerVal === "undefined") return;
      if (triggerVal === null) return;
      console.log(field.name, "trigger", triggerVal);
      //after we read the value from previous lookup
      //then we can check if these field require specific trigger condition
      //like if one lookup should have these value, and another lookup field must contain specific value
      //before allowing these lookup field to be displayed.
      if (typeof field.triggerCondition !== "undefined") {
        console.log(
          "pass condition : ",
          field.name,
          field.triggerCondition(store),
          store
        );
        if (!field.triggerCondition(store)) return;
      }
    }

    if (typeof field.show_if !== "undefined") {
      if (!field.show_if(data)) return "";
    }
    if (field.readonly === true) {
      return (
        <LabelAndText
          key={index}
          id={index}
          text={
            typeof field.resource_label !== "undefined"
              ? field.resource_label(data)
              : values[field.name]
          }
          label={field.label}
        />
      );
    }
    if (field.type === "text") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          hint={field.hint}
          label={unslug(field.label)}
          value={
            typeof data !== "undefined" && data !== null
              ? data[field.name]
              : null
          }
          onEnterKey={(text) => {}}
          onChange={(value) => {
            let v = values ? values : {};
            v[field.name] = value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "textarea") {
      return (
        <LabelAndTextArea
          key={index}
          id={index}
          hint={field.hint}
          value={
            typeof data !== "undefined" && data !== null
              ? data[field.name]
              : null
          }
          label={unslug(field.label)}
          onChange={(event) => {
            let v = values ? values : {};
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "label") {
      return (
        <LabelAndText
          key={index}
          label={field.label}
          labelSize={24}
          labelColor={"#cc0000"}
        />
      );
    } else if (field.type === "date") {
      let v = values ? values : {};
      if (v === null) v = {};
      if (typeof v[field.name] === "undefined") v[field.name] = moment();
      else v[field.name] = moment(v[field.name]);
      return (
        <div key={index} style={{ marginBottom: 15 }}>
          <h4 style={{ margin: 0, marginBottom: 5, fontSize:'100%',fontWeight:'bold' }}>{field.label}</h4>
          {field.hint ? <p>{field.hint}</p> : null}
          <DatetimePickerTrigger
            moment={v[field.name]}
            showTimePicker={false}
            onChange={(date) => {
              v[field.name] = date;
              setValues(v);
              setUpdate(update + 1);
            }}
          >
            <input
              type="text"
              value={v[field.name].format("DD-MM-YYYY")}
              readOnly
              style={{
                padding: "6px 10px",
                fontSize: "100%",
                borderRadius: 4,
                border: "1px solid #ccc",
                marginTop: 10,
                width: "100%",
              }}
            />
          </DatetimePickerTrigger>
        </div>
      );
    } else if (field.type === "datetime") {
      let v = values ? values : {};
      if (typeof v[field.name] === "undefined") v[field.name] = moment();
      return (
        <div key={index} style={{ marginBottom: 15 }}>
          <h4 style={{ margin: 0, marginBottom: 5 }}>{field.label}</h4>
          {field.hint ? <p>{field.hint}</p> : null}
          <DatetimePickerTrigger
            moment={v[field.name]}
            onChange={(date) => {
              v[field.name] = date;
              setValues(v);
              setUpdate(update + 1);
            }}
          >
            <input
              type="text"
              value={v[field.name].format("DD-MM-YYYY HH:mm")}
              readOnly
              style={{
                padding: "6px 10px",
                fontSize: "100%",
                borderRadius: 4,
                border: "1px solid #ccc",
                marginTop: 10,
                width: "100%",
              }}
            />
          </DatetimePickerTrigger>
        </div>
      );
    } else if (field.type === "number") {
      return (
        <LabelAndNumberInput
          key={index}
          id={index}
          hint={field.hint}
          value={values[field.name] ? values[field.name] : field.defaultValue}
          label={unslug(field.label)}
          onChange={(val) => {
            let v = values ? values : {};
            v[field.name] = val;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "richtext") {
      
      let vv = values ? values : {};
      if (typeof vv[field.name] === "undefined") vv[field.name] = "";
      return (
        <div key={"rt" + index} style={{ marginTop: 15, marginBottom: 15 }}>
          <div
            style={{
              fontWeight: "bold",
              paddingBottom: 15,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: field.label }}></span>
            {field.hint ? <p>{field.hint}</p> : null}
          </div>
          <div
            style={{
              border: "1px solid #ccc",
              padding: 4,
            }}
          >
            <RichTextEditor
              content={vv[field.name]}
              handleContentChange={(value, delta, source, editor) => {
                let v = values ? values : {};
                v[field.name] = editor.getHTML();

                let w = wordcount;
                w[field.name] = wordCount(editor.getHTML());

                setValues(v);
                setWordcount(w);
              }}
              placeholder="ketik disini..."
              theme="snow"
            />
          </div>
        </div>
      );
    } else if (field.type === "password") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          password={true}
          label={field.label}
          onEnterKey={() => {}}
          onChange={(value) => {
            console.log("password");
            let v = values ? values : {};
            v[field.name] = value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "image") {
      return (
        <LabelAndImagePicker
          key={index}
          hint={field.hint}
          action={uploadAction}
          value={
            typeof data !== "undefined" && data !== null
              ? data[field.name]
              : null
          }
          label={field.label}
          onChange={(file_uri, fileObj) => {
            let v = values ? values : {};
            if (fileObj) {
              let img = {
                original: fileObj.original.Location,
                hp_file: fileObj.hp.Location,
                thumbnail_file: fileObj.thumbnail.Location,
              };
              v[field.name] = img;
            } else {
              v[field.name] = file_uri;
            }

            setValues(v);
          }}
        />
      );
    } else if (field.type === "file") {
      return (
        <LabelAndFilePicker
          key={index}
          action={uploadAction}
          label={field.label}
          filetypes={field.filetypes}
          hint={field.hint}
          onChange={(file_uri) => {
            let v = values ? values : {};
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "video") {
      return (
        <LabelAndVideoPicker
          key={index}
          hint={field.hint}
          action={uploadAction}
          value={false}
          label={field.label}
          onChange={(file_uri) => {
            let v = values ? values : {};
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "password") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          password={true}
          label={unslug(field.label)}
          onChange={(value) => {
            let v = values ? values : {};
            v[field.name] = value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "media") {
      return (
        <LabelAndTextInput
          placeholder={"http://"}
          key={index}
          id={index}
          hint={field.hint}
          value={
            typeof data !== "undefined" && data !== null
              ? data[field.name]
              : null
          }
          label={unslug(field.label)}
          onChange={(value) => {
            let v = values ? values : {};
            v[field.name] = value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "auto") {
      let text = "";

      if (typeof field.text !== "undefined") {
        text = typeof field.text === "function" ? field.text(data) : field.text;
      } else if (typeof field.value === "function") {
        text = field.value(data);
      } else {
        text = field.value;
      }
      if (field.label === null) return;
      return (
        <LabelAndText
          key={index}
          id={index}
          labelSize={18}
          text={text}
          label={field.label}
        />
      );
    } else if (field.type === "plugin") {
      let v = values ? values : {};
      if (typeof v[field.name] === "undefined") v[field.name] = null;
      return field.component({
        data: v[field.name],
        field,
        onUpdate: (data) => {
          // console.log("data", data);
          v[field.name] = data;
          setValues(v);
        },
      });
    } else if (field.type === "lookup") {
      if (typeof field.values !== "undefined")
        return (
          <ArrayLookup
            data={data}
            key={index}
            id={field.name + "-" + moment().unix()}
            label={unslug(field.label)}
            defaultValue={field.defaultValue}
            field={field.hint}
            values={
              typeof field.values === "function" ? field.values() : field.values
            }
            stores={store}
            onChange={(value) => {
              let v = values ? values : {};
              v[field.name] = value;

              if (typeof field.store !== "undefined") {
                let s = store;
                s[field.store] = value;
                setStore(s);
              }

              setValues(v);
              setUpdate(update + 1);
            }}
          ></ArrayLookup>
        );
      else if (field.searchable)
        return (
          <ResourceSearchableLookup
            resource={
              typeof field.resource === "function"
                ? field.resource(customData, store)
                : field.resource
            }
            data={data}
            id={field.name}
            key={index}
            label={unslug(field.label)}
            values={field.values}
            defaultValue={field.defaultValue}
            value={field.value}
            hint={field.hint}
            field={field}
            dataKey={field.key}
            params={field.params}
            stores={store}
            isMulti={typeof field.multiple !== "undefined" ? true : false}
            onChange={(value) => {
              let v = values ? values : {};
              v[field.name] = value;

              if (typeof field.store !== "undefined") {
                let s = store;
                s[field.store] = value;
                setStore(s);
              }
              setValues(v);
              setUpdate(update + 1);
            }}
          />
        );
      else
        return (
          <ResourceLookup
            resource={
              typeof field.resource === "function"
                ? field.resource(customData, store)
                : field.resource
            }
            params={field.params}
            id={field.name}
            key={field.name}
            label={unslug(field.label)}
            values={field.values}
            field={field}
            hint={field.hint}
            value={field.value}
            defaultValue={field.defaultValue}
            stores={store}
            data={data} //default data if exist
            onChange={(value) => {
              let v = values ? values : {};
              v[field.name] = value;
              if (typeof field.store !== "undefined") {
                let s = store;
                s[field.store] = value;
                setStore(s);
              }
              setValues(v);
              setUpdate(update + 1);
            }}
          />
        );
    } else if (field.type === "radio") {
      /* if (!data && typeof field.initialValue !== "undefined") {
        let v = values ? values : {};
        if (typeof v[field.name] === "undefined")
          v[field.name] = field.initialValue ? field.initialValue : 0;
        v[field.name] = field.initialValue ? field.initialValue : 0;
      }*/

      return (
        <LabelAndRadio
          key={index}
          id={field.name + "-" + moment().unix()}
          hint={field.hint}
          label={unslug(field.label)}
          data={data}
          value={
            typeof field.value === "function"
              ? field.value(values[field.name])
              : data
              ? data[field.name]
              : 0
          }
          values={field.values}
          onChange={(value) => {
            let v = values ? values : {};
            v[field.name] = value;

            setValues(v);
            setUpdate(update + 1);
          }}
        ></LabelAndRadio>
      );
    } else if (field.type === "toa") {
      return (
        <div key={index}>
          <p>
            Please read our terms of agreements <a href="#">here</a>
          </p>
          <p>Do you agree with the terms and agreements? </p>
          <LabelAndRadio
            id={field.name + "-yes"}
            hint={field.hint}
            label={false}
            value={
              typeof field.initialValue !== "undefined" ? field.initialValue : 0
            }
            values={[
              {
                text: "Agree",
                value: 1,
              },
              {
                text: "Refuse",
                value: 0,
              },
            ]}
            onChange={(value) => {
              let v = values ? values : {};
              v[field.name] = value;
              setValues(v);
            }}
          ></LabelAndRadio>
        </div>
      );
    } else if (field.type === "agree") {
      return (
        <div key={index}>
          <LabelAndCheckbox
            id={field.name + "-yes"}
            hint={field.hint}
            label={field.label}
            value={
              typeof field.initialValue !== "undefined" ? field.initialValue : 0
            }
            values={[
              {
                text: "Agree",
                value: 1,
              },
              {
                text: "Refuse",
                value: 0,
              },
            ]}
            onChange={(value) => {
              let v = values ? values : {};
              v[field.name] = value;
              setValues(v);
            }}
          ></LabelAndCheckbox>
        </div>
      );
    } else if (field.type === "custom") {
      return (
        <div key={index}>
          <h4>{field.label}</h4>
          {field.component}
        </div>
      );
    } else if (field.type === "divider") {
      return (
        <div
          style={{
            borderBottom: "1px solid #ffffff",
            paddingBottom: 10,
            paddingTop: 0,
            marginBottom: 15,
          }}
        >
          <h3>{field.label}</h3>
        </div>
      );
    } else if (field.type === "multiselect") {
      return (
        <MultiSelector
          customData={customData}
          stores={store}
          field={field}
          limit={field.limit ? field.limit : 9999}
          label={field.label}
          initialValue={field.defaultValue}
          hint={field.hint}
          key={field.name}
          data={data}
          value={(data) => {
            return data;
          }}
          onChange={(value) => {
            let v = values ? values : {};
            v[field.name] = value;
            setValues(v);
          }}
        />
      );
    } else {
      return null;
    }
  };
  return (
    <SimpleCard title={title}>
      <div>
        <div>
          {moduleProps.intro ? (
            <div style={{ marginBottom: 15 }}>{moduleProps.intro}</div>
          ) : null}
          {fields.map((field, index) => {
            return (
              <div key={"f" + index}>
                {renderField(field, index)}
                {typeof fieldErrors[field.name] !== "undefined" &&
                fieldErrors[field.name] === true &&
                triggered(field, store) ? (
                  <div
                    style={{
                      background: "#cc0000",
                      padding: 10,
                      marginBottom: 14,
                      marginTop: -18,
                    }}
                  >
                    {field.validationError}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div>
          {progress ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner spinner-bubble spinner-bubble-primary"></div>
              <span>Mohon tunggu sebentar...</span>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              {typeof cancelText !== "undefined"
                ? onCancel && (
                    <Button
                      onClick={() => {
                        onCancel();
                        return;
                      }}
                      color="secondary"
                      variant={"outlined"}
                      style={{ marginRight: 30 }}
                      title={cancelText ? cancelText : "Cancel"}
                    ></Button>
                  )
                : null}
              {onSubmit && (
                <Button
                  title={submitText ? submitText : "Send"}
                  onClick={() => {
                    //add auto values here if possible

                    let final_values = getAllowedValues(values, fields, data);
                    final_values = addDefaultRadioValue(final_values,fields,data);
                    final_values = getAutoValues(final_values, fields, data);

                    final_values = fixDates(final_values, fields);

                    final_values = defaultValues(final_values, fields);

                    let invalids = checkMandatories(final_values, fields);

                    let validationErrors = checkValidationErrors(
                      final_values,
                      fields
                    );
                    let invalid_words = checkWordCounts(
                      final_values,
                      fields,
                      wordcount
                    );
                   console.log({invalid_words,invalids,validationErrors})
                    if (
                      invalids.length === 0 &&
                      invalid_words.length === 0 &&
                      validationErrors.length === 0
                    ) {
                      //console.log(6, { final_values });
                      onSubmit(final_values);
                      return;
                    }

                    if (typeof onError !== "undefined" && invalids.length > 0){
                      onError(invalids);
                      console.log("onError",invalids);
                    }
                     
                    if (
                      typeof onWordcountError !== "undefined" &&
                      invalid_words.length > 0
                    ) {
                      onWordcountError(invalid_words);
                    }
                  }}
                  color="primary"
                  variant={"contained"}
                ></Button>
              )}
            </div>
          )}
        
        </div>
      </div>
    </SimpleCard>
  );
}

