import React, { useState,useEffect,Suspense } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import routes from "./routes";
import "./App.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

function App(props) {

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
      {routes.map((route, index) => {
       // console.log("route", _route, route.path);
        // Render more <Route>s with the same paths as
        // above, but different components this time.
        return (
          <ThemeProvider key={index} theme={theme}>
            <Route
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          </ThemeProvider>
        );
      })}
      </Suspense>
    </Router>
  );
}

export default connect()(App);