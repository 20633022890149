import React from "react";
import styled from "styled-components";

import { Instagram, Email, Whatsapp } from "../Assets";

const Footer = () => {
  const FooterWrapper = styled.section`
    background-color: black;
    font-family: "Poppins Regular";
    font-size: 15px;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  `;
  return (
    <FooterWrapper>
      <div className="container mt-4">
        <div className="row clearfix text-light px-4 py-4">
          <div className="col-lg-9 col-md-12 col-sm-12">
            <h5>ShioKaya</h5>
            <p>
              Dalam deskripsi Shio Kaya ini, anda mempelajari apa yang
              sebenarnya mendorong, menginspirasi, dan tantangan dari
              karakteristik alamiah Anda yang unik. yang bisa membantu anda
              dalam membangun keberhasilan dengan lebih efektif dan efisien,
              menghemat waktu dan tenaga anda.
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 my-auto mx-auto">
            <div className="d-flex justify-content-start mb-2">
              <img
                src={Instagram.url}
                alt={Instagram.alt}
                width="10%"
                height="auto"
              />
              <span className="ml-2 my-auto"> @tentangshiokaya</span>
            </div>

            <div className="d-flex justify-content-start mb-2">
              <img
                src={Whatsapp.url}
                alt={Whatsapp.alt}
                width="10%"
                height="auto"
              />
              <span className="ml-2 my-auto"> +62 877-8814-80544</span>
            </div>

            <div className="d-flex justify-content-start mb-2">
              <img src={Email.url} alt={Email.alt} width="10%" height="auto" />
              <span className="ml-2 my-auto"> support@shiokaya.com</span>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
