/**
 * reference:
 * https://alligator.io/react/react-select/
 */
import React, { useState, useEffect } from "react";

import Api from "../../Services/Api";
import AsyncSelect from "react-select";

export default function ResourceSearchableLookup(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    id,
    label,
    resource,
    field,
    isMulti,
    multiValues,
    stores,
    params,
    defaultValue,
    data
  } = props;

  const api = new Api();

  const [value, setValue] = useState(null);
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    lookup();
  }, []);
  
  useEffect(() => {
    lookup();
  }, [search]);

  useEffect(() => {
    setValue(props.value);
  }, [values]);


  const lookup = () => {

    //retrieve lookup
    let endpoint = "/" + (typeof resource === 'function' ? resource() : resource);
    
    if (typeof field.resource === "function") {
      endpoint = field.resource(null, stores);
    }
    let additional_params = params ? params(stores) : {};
    api
      .crud({
        endpoint,
        actionType: "RETRIEVE",
        data: {
          search,
          ...additional_params
        },
      })
      .then((response) => {
        let node =
          typeof field.resourceNode !== "undefined"
            ? field.resourceNode
            : "data";

        if (response[node].length > 0) {
          let vals = [];

          response[node].map((item) => {
            
            if (typeof field.value === "function") {
              vals.push(field.value(item));
            } else {
              vals.push({
                label: item[field.valueField],
                value: item[field.key],
              });
            }
          });
          setValues(vals);
        }

        //setValue(value);
      })
      .catch((err) => {
        console.log(err);
        setValues([]);
      });
  }
  if (typeof values === "undefined") return "Loading...";
  //if (value === null) return "...";



  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
      <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15,
        }}
      >
        {label} 
      </div>
      <div>
        <AsyncSelect
          defaultValue={props.defaultValue ? props.defaultValue(data) : null}
          isMulti={isMulti}
          options={values}
          onInputChange={(inputValue) => {
            setSearch(inputValue);
          }}
          onChange={(item) => {
            if (Array.isArray(item)) {
              let strVal = [];
              item.map((t) => {
                strVal.push(t.value);
              });
              onChange(strVal.join(","));
            } else {
              onChange(item.value);
            }
          }}
        />
      </div>
    </div>
  );
}
