import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { WhatsApp, Email } from "@material-ui/icons";
export default function Footer(props) {
  return (
    <div
      style={{
       
        padding: 30,
        paddingTop: 34,
        background: "#000000",
       
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <div
            style={{
              fontSize: "120%",
              lineHeight: "150%",
              padding: 10,
              color: "white",
            }}
          >
            <h4 style={{ margin: 0 }}>ShioKaya</h4>
            <p>
              Dengan ShioKaya, anda akan mempelajari apa yang
              sebenarnya mendorong, menginspirasi, dan tantangan dari
              karakteristik alamiah anda yang unik, yang bisa membantu anda
              dalam membangun keberhasilan dengan lebih efektif dan efisien,
              menghemat waktu dan tenaga anda.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            style={{
              fontSize: "120%",
              lineHeight: "150%",
              padding: 10,
              color: "white",
            }}
          >
            <h3>Hubungi Kami</h3>
            <p>
              <WhatsApp style={{marginRight:5}}/> <span style={{
                top:-4,
                position:'relative',
              }}>+62 821-3000-8908</span>
              <br />
              <Email style={{marginRight:10}}/><span style={{
                top:-4,
                position:'relative',
              }}>support@shiokaya.com</span>
            </p>

            {/*}
              <ul
                style={{
                  listStyle: "none",
                  padding: 0
                }}
              >
                <li>Tentang Kami</li>
                <li>Produk &amp; Layanan</li>
                <li>Member Area</li>
                <li>Kebijakan Privasi</li>
                <li>Kontak</li>
              </ul>
              {*/}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
