import React from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyle from "./styles/DashboardStyles";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Sidebar from "./common/Sidebar";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Footer from "./common/Footer";
import DashboardBreadcrumbs from "./common/DashboardBreadcrumbs";

import CallAction from "../redux/actions/CallAction";
import { userHasAccess, hasValue } from "../libs/helper";
import Table from "../Parts/Widgets/Table";
import Button from "../Parts/Widgets/Button";
import accounting from "accounting";
import { FaAffiliatetheme, FaCartPlus, FaClosedCaptioning } from "react-icons/fa";
import ModuleEditForm from "./ModuleEditForm";
import LabelAndText from "./widgets/LabelAndText";
import moment from "moment";
import styled from "styled-components";
import slugify from "slugify";

const Submenu = styled.div`
  background: #e5e5e5;
  margin-top: 15px;
  margin-bottom: 15px;
  ul {
    list-style: none;
    padding: 0;
  }
  ul li {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 15px;
    cursor: pointer;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  ul li.active,
  ul li:hover {
    background: #cccccc;
  }
  ul li a {
    color: #000000;
    text-decoration: none;
  }
  @media (max-width: 499px) {
    ul li {
      display: inline-block;
      padding-right: 15px;
      border-bottom: 0;
    }
  }
`;

class LandingPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      showSidebar: false,
      pendings: [],
      qty: 0,
      fetching: false,
      data: [],
      pos: "overview",
      item: null,
      affiliate: null,
      affiliate_status: 0,
    };
    this.Setup = this.Setup.bind(this);
    this.Access = this.Access.bind(this);
    this.Overview = this.Overview.bind(this);
    this.NoAccess = this.NoAccess.bind(this);
    this.Permalink = this.Permalink.bind(this);
    this.update = this.update.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
  }
  componentDidMount() {
    const { call_action, callAction } = this.props;
    window.addEventListener("resize", (event) => {
      /*this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });*/
    });

    callAction(call_action, "dashboard", {
      endpoint: "/affiliate/summary/" + localStorage.getItem("affiliate_id"),
      scenario: "get",
    });
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action.dashboard !== call_action.dashboard) {
      this.handleUpdate(call_action.dashboard);
    }
    if (_props.call_action.update !== call_action.update) {
        this.handleUpdateProfile(call_action.update);
      }
  }
  handleUpdate(payload) {
    if (!hasValue(payload)) return;

    this.setState({
      fetching: false,
      affiliate: payload.affiliate ? payload.affiliate : null,
      affiliate_status: payload.affiliate_status ? payload.affiliate_status : 0,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  update(values){
      const {callAction, call_action} = this.props;
      callAction(call_action,'update',{
          endpoint:'/affiliates/setting/update',
          data:values,
          scenario:'post'
      });

      this.setState({
          fetching:true
      })
  }
  handleUpdateProfile(payload){
    const {callAction, call_action} = this.props;
    this.setState({
        fetching:FaClosedCaptioning
    })
    if (!hasValue(payload)) return;

    callAction(call_action, "dashboard", {
        endpoint: "/affiliate/summary/" + localStorage.getItem("affiliate_id"),
        scenario: "get",
      });

  }
  Overview({ affiliate }) {
    return (
      <div style={{ padding: 20 }}>
        <h4 style={{margin:0,fontWeight:700}}>Tentang Landing Page</h4>
        <p>
          Landing Page diperuntukkan untuk affiliasi yang tidak memiliki Website.
        </p>
        <p>
          Manfaatkan fitur landing page ini untuk dibagikan melalui akun Sosial Media anda.
        </p>
        <Button title={`Konfigurasi`} onClick={() => {
            this.setState({
              pos: "setup",
            });
          }}></Button>
        
      </div>
    );
  }
  Setup({ affiliate, fetching }) {
    console.log({affiliate})
    return (
      <div style={{ padding: 20 }}>
        <h4 style={{margin:0,fontWeight:700}}>Konfigurasi</h4>
        <p>Mohon lengkapi form dibawah ini:</p>
       
        <ModuleEditForm
            open={true}
            progress={fetching}
            data={affiliate}
            moduleProps={{
              fields: [
                {
                  name: "name",
                  label: "Nama Lengkap",
                  type: "text",
                  
                },
                {
                    name: "deskripsi",
                    label: "Deskripsi singkat tentang anda",
                    type: "richtext",
                  },
                  {
                    name: "industry",
                    label: "Industri",
                    type: "text",
                  },
                  {
                    name: "photo_url",
                    label: "Foto Diri",
                    type: "image",
                  },
                  {
                    name: "photos",
                    label: "Foto-foto lainnya",
                    type: "multi-images",
                  },
              ],
            }}
            submitText={"Simpan"}
            progress={fetching}
            onSubmit={(values) => {
              this.update(values);
            }}
            
            onError={(invalids) => {
              console.log("invalids:", invalids);
            }}
          />
      </div>
    );
  }
  Permalink({ affiliate }) {
    if (affiliate.link === null) {
      return (
        <div style={{ padding: 20 }}>
          <p>Silahkan konfigurasi Landing Page anda terlebih dahulu!</p>
          <a
            href="#"
            onClick={() => {
              this.setState({
                pos: "setup",
              });
            }}
          >
            Konfigurasi
          </a>
        </div>
      );
    }
    return (
      <div style={{ padding: 20 }}>
        <h4 style={{margin:0,fontWeight:700}}>URL Landing Page Anda</h4>
        <p>Berikut adalah alamat Landing Page anda:</p>
        <p>
          <a
            href="https://landing.shiokaya.com/{affiliate.link}"
            target="_blank"
          >
            https://landing.shiokaya.com/{affiliate.link}
          </a>
        </p>
        <p>Bagikan alamat diatas melalui Sosial Media, Email, Whatapp anda.</p>
      </div>
    );
  }
  NoAccess() {
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <ul>
            <li>
              <a href="#">Setup</a>
            </li>
            <li>
              <a href="#">URL</a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={8}></Grid>
      </Grid>
    );
  }
  Access({ pos, affiliate, fetching }) {
    return (
      <Grid container>
        <Grid item xs={12} md={2}>
          <Submenu>
            <ul>
              <li class={pos === "overview" ? "active" : "none"}>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      pos: "overview",
                    });
                  }}
                >
                  Info
                </a>
              </li>
              <li class={pos === "setup" ? "active" : "none"}>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      pos: "setup",
                    });
                  }}
                >
                  Konfigurasi
                </a>
              </li>
              <li class={pos === "url" ? "active" : "none"}>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      pos: "url",
                    });
                  }}
                >
                  Alamat URL
                </a>
              </li>
            </ul>
          </Submenu>
        </Grid>
        <Grid item xs={12} md={10}>
          {pos === "overview" ? <this.Overview affiliate={affiliate} /> : null}
          {pos === "setup" ? <this.Setup affiliate={affiliate} fetching={fetching} /> : null}
          {pos === "url" ? <this.Permalink affiliate={affiliate} /> : null}
        </Grid>
      </Grid>
    );
  }
  render() {
    const { classes, moduleProps, modules, history, getSummary } = this.props;
    const { snackbar, snackbarText, fetching, pos, affiliate } = this.state;
    if (fetching) return <p>Please wait...</p>;
    return (
      <div className={classes.root}>
        <TopLoggedInNav history={history} modules={modules} />

        <Grid container>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DashboardBreadcrumbs
                    items={[
                      {
                        label: "Landing Page",
                        path: "landing-page",
                      },
                    ]}
                  />
                </Grid>
                <Grid item md={12}></Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div style={{ minHeight: window.innerHeight }}>
          {(affiliate !== null && pos === "access") ||
          pos === "overview" ||
          pos === "url" ||
          pos === "setup" ? (
            <this.Access pos={pos} affiliate={affiliate} fetching={fetching} />
          ) : null}
        </div>
        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(LandingPageContainer));
