import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ResponsiveMenu from "react-responsive-navbar";
import styled from "styled-components";
import {
  FaBars,
  FaRegWindowClose,
  FaPenSquare,
  FaPen,
  FaCalculator,
  FaTrophy,
  FaPercent,
  FaStar,
  FaCircle,
  FaClock,
  FaUserAlt,
  FaCheck,
  FaLayerGroup,
  FaCheckCircle,
  FaChartBar,
  FaBrain,
  FaRegLightbulb,
  FaRegHourglass,
  FaRegThumbsUp,
} from "react-icons/fa";
import { AppBar, Toolbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { userHasAccess } from "../../libs/helper";

const slugify = require("slugify");

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#333",
  },
  menu: {
    minWidth: 200,
    top: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const canAccess = (name) => {
  
};
export default function TopLoggedInNav(props) {
 
  const { history, sidebar, name, modules, moduleProps } = props;
  const username = localStorage.getItem("name");
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleDrawerOpen = () => {
    setToggleDrawer(true);
  };

  const handleDrawerClose = () => {
    setToggleDrawer(false);
  };
  return (
    <div style={{
      position:"fixed",
      width:"100%",
      zIndex:9999
    }}>
      <AppBar id="menu-appbar" position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
           
          </Typography>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(evt) => {
              setAnchorEl(evt.currentTarget);
              setOpen(true);
            }}
            color="inherit"
          >
            <AccountCircle />
            &nbsp;{username}
          </IconButton>
          <Menu
            id="menu-appbar"
            className={classes.menu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            style={{ top: 50 }}
          >
            <MenuItem onClick={() => {}}>Pengaturan</MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/logout");
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img style={{ height: 26 }} src={'https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/logo192.png'} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        
      
       
       
        <List>
        {modules.map((module, index) => {
            if(typeof module.menu !== 'undefined'){
              if(!module.menu) return null;
            }
            return (
              <ListItem
                button
                key={index}
                onClick={() => {
                  history.push(
                    "/" +
                      slugify(module.slug, {
                        replacement: "-", // replace spaces with replacement
                        remove: null, // regex to remove characters
                        lower: true, // result in lower case
                      })
                  );
                }}
              >
                {module.icon}
                <ListItemText primary={module.name} />
              </ListItem>
            );
          })}
        </List>
       
      </Drawer>
    </div>
  );
}
