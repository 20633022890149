import React from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyle from "./styles/DashboardStyles";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Sidebar from "./common/Sidebar";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Footer from "./common/Footer";
import DashboardBreadcrumbs from "./common/DashboardBreadcrumbs";

import CallAction from "../redux/actions/CallAction";
import { userHasAccess, hasValue } from "../libs/helper";
import Table from "../Parts/Widgets/Table";
import Button from "../Parts/Widgets/Button";
import accounting from "accounting";
import { FaCartPlus } from "react-icons/fa";
import ModuleForm from "./ModuleForm";
import LabelAndText from "./widgets/LabelAndText";
import moment from "moment";

class RevenueContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      showSidebar: false,
      pendings: [],
      qty:0,
      fetching: false,
      data: [],
      pos: "listing",
      item: null,
    };
    this.Listing = this.Listing.bind(this);
    this.Withdraw = this.Withdraw.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.Result = this.Result.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.Failed = this.Failed.bind(this);
  }
  componentDidMount() {
    const { call_action, callAction } = this.props;
    window.addEventListener("resize", (event) => {
      /*this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });*/
    });

    callAction(call_action, "dashboard", {
      endpoint: "/affiliates/revenue",
      scenario: "get",
    });

  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action.dashboard !== call_action.dashboard) {
      this.handleUpdate(call_action.dashboard);
    }
    if (_props.call_action.withdraw !== call_action.withdraw) {
      this.handleWithdraw(call_action.withdraw);
    }
   
  }
  handleUpdate(payload) {
    if (!hasValue(payload)) return;

    this.setState({
      fetching: false,
      data: payload.revenue ? payload.revenue : [],
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  withdraw(input){
    const {callAction, call_action} = this.props;
    callAction(call_action, "withdraw", {
      endpoint: "/affiliates/withdraw",
      scenario: "post",
      data:{
        amount: input.amount
      }
    });
    this.setState({
      fetching:true
    });

    
  }
  handleWithdraw(payload){
    if(typeof payload === 'undefined') return;
    if(payload === null) return;
    if(payload.status === 1){
      this.setState({
        pos:'result',
        fetching:false,
      })
    }else{
      this.setState({
        pos:'failed',
        fetching:false,
      })
    }
  }
  Listing({ fetching, data } ) {
  
    return (
      <div style={{ padding: 15 }}>
        
        <Table
          progress={fetching}
          data={data}
          onAction={(name, action, item) => {
            
          }}
          moduleProps={{
            actions: [
              
            ],
            fields: [
              {
                name: "created_at",
                label: "Date",
                type: "date",
                render:(row)=>{
                  return moment(row.created_at).format("DD/MM/YYYY");
                }
              },
              {
                name: "transaction_id",
                label: "Transaction ID",
                type: "text",
                
              },
              {
                name: "amount",
                label: "Revenue",
                type: "number",
                render: (row) => {
                  return `Rp. ${accounting.formatNumber(row.amount, 0, ".")}`;
                },
              },
            ],
          }}
        />
        <div style={{width:200}}>
          <Button title={`Withdraw`} onClick={()=>{
            this.setState({
              pos:'withdraw'
            });
          }}/>

        </div>
      </div>
    );
  }
  Withdraw({ fetching, data } ) {
  
    return (
      <div style={{ padding: 15,maxWidth:'100%',margin:'auto 0', textAlign:'center' }}>
       
        <div style={{maxWidth:600, border:'1px solid #ccc',borderRadius:20,padding:20,margin:'0 auto'}}>
          <h4>Withdrawal Request</h4>
          <p>You can request a revenue withdrawal here. Please note that the processing fee will be applied.</p>
          <ModuleForm
            progress={fetching}
            confirm={true}
            confirmText={(input)=>{
              return  `are you sure to withdraw Rp. ${accounting.formatNumber(input.amount,0,'.')}?`
            }}
            moduleProps={{
              fields: [
                {
                  name: "amount",
                  label: "Amount",
                  type: "number",
                },
              ],
            }}
            submitText={"Withdraw"}
            progress={fetching}
            cancelText={`Cancel`}
            onSubmit={(values) => {
              this.withdraw(values);
            }}
            onCancel={()=>{
              this.setState({
                pos:'listing'
              })
            }}
            onError={(invalids) => {
              console.log("invalids:", invalids);
            }}
          />
        </div>
       
      </div>
    );
  }
  Result({ fetching, data } ) {
  
    return (
      <div style={{ padding: 15,maxWidth:600}}>
       
        <div style={{border:'1px solid #ccc',borderRadius:20,padding:20,margin:'0 auto'}}>
          <h4>Your Withdrawal Request</h4>
          <p>We have recieved your withdrawal request. We will review and proceed with the refund as soon as possible!</p>
          <div>
            <Button title={`Continue`} onClick={()=>{
              this.setState({pos:'listing'});
            }}/>
          </div>
        </div>
       
      </div>
    );
  }
  Failed({ fetching, data } ) {
  
    return (
      <div style={{ padding: 15,maxWidth:600}}>
       
        <div style={{border:'1px solid #ccc',borderRadius:20,padding:20,margin:'0 auto'}}>
          <h4>Sorry!</h4>
          <p>We are unable to process your request, Please try again later!</p>
          <div>
            <Button title={`Continue`} onClick={()=>{
              this.setState({pos:'listing'});
            }}/>
          </div>
        </div>
       
      </div>
    );
  }
  render() {
    const { classes, moduleProps, modules, history, getSummary } = this.props;
    const { snackbar, snackbarText, fetching, vouchers, pos, data } = this.state;
    if (fetching) return <p>Please wait...</p>;
    return (
      <div className={classes.root}>
        <TopLoggedInNav history={history} modules={modules} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DashboardBreadcrumbs />
                </Grid>
                
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            {pos === "listing" ? (
              <this.Listing fetching={fetching} data={data} />
            ) : null}
            {pos === "withdraw" ? (
              <this.Withdraw fetching={fetching} />
            ) : null}
            {pos === "result" ? (
              <this.Result fetching={fetching} />
            ) : null}

            {pos === "failed" ? (
              <this.Failed fetching={fetching} />
            ) : null}
           
          </Grid>
        </Grid>

        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(RevenueContainer));
