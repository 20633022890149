import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

export default function DashboardBreadcrubs(props) {
  const { items } = props;
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/dashboard" onClick={() => {}}>
        Dashboard
      </Link>
      {typeof items !== 'undefined' ? items.map((item, index) => {
        return (
          <Link key={index} color="inherit" href={item.path} onClick={() => {}}>
            {item.label}
          </Link>
        );
      }) : null}
    </Breadcrumbs>
  );
}
