import React, { useState, useEffect } from "react";
import moment from "moment";
import { userHasAccess, text } from "../../libs/helper";
import ReactPaginate from "react-paginate";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function (props) {
  const {
    crud,
    moduleProps,
    modules,
    location,
    history,
    search,
    onEdit,
    onDelete,
    onAction,
    defaultPageSize,
    defaultPageSizeOptions,
    title,
    data,
    total,
    onPageChange,
    total_page,
    page,
    per_page,
    onSort,
  } = props;

  const [tableRef, setTableRef] = useState(React.createRef());
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionOpts, setactionOpts] = useState({});
  const [deletedRow, setDeletedRow] = useState(null);

  const [actionName, setActionName] = useState(null);
  const [actionText, setActionText] = useState(null);
  const [actionUri, setActionUri] = useState(null);
  const [actionOpenWindow, setActionOpenWindow] = useState(null);
  const [confirm_action, setConfirmAction] = useState(false);
  const [open_action_component, setOpenActionComponent] = useState(null);
  const [other_action, setOtherAction] = useState(null);
  const [cols, setCols] = useState([]);
  const [update, setUpdate] = useState(0);
  const [actions, setActions] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    setActions(getAdditionalActions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (update > 0) return;

    let [columns, data, pageSize] = getData();
    setCols(columns);
  }, [moduleProps]);

  const getFilters = function () {
    if (typeof moduleProps.parentFilters === "undefined") return;

    let filters = {};

    moduleProps.parentFilters.map((item) => {
      filters[item.field] = item.value(location.state.data);
    });

    return filters;
  };
  const getData = () => {
    /*if (!moduleProps.crud) {
      if (typeof crud === "undefined") return [[], []];
      if (typeof crud.payload === "undefined") return [[], []];
      if (crud.payload === null) return [[], []];
      data = crud.payload.data;
    }*/

    let cols = [];
    if (data && data.length > 0) {
      let row = data[0];
      for (let k in row) {
        let field = getField(moduleProps.fields, k);
        if (!field) continue;
        cols = compileColumns(cols, field, k);
      }
    }
    if (cols.length === 0) {
      //if cols still empty, then we use the defined columns setup
      moduleProps.fields.map((field) => {
        let k = field.name;
        cols = compileColumns(cols, field, k);
      });
    }

    return [cols, data, 5];
  };
  const isVisible = (field) => {
    if (typeof field.visible === "undefined") return true;
    return field.visible;
  };
  const compileColumns = (cols, field, k) => {
    let fields = [];

    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return cols;
      }
    }

    if (!isVisible(field)) return cols;

    // if (this.displayedInListing(fields, k)) {

    if (isImage(fields, k)) {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return <img src={rowData[k]} height={70} />;
        },
      });
    } else if (field.type === "divider") {
    } else if (field.type === "number") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (rowData[k] === null) return "-";
          if (typeof field.resource_label !== "undefined") {
            return field.resource_label(rowData);
          }
          return rowData[k]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
      });
    } else if (field.type === "enum") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return field.values[rowData[k]];
        },
      });
    } else if (field.type === "datetime") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "date") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          //  return rowData[k];
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "lookup") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "undefined")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          if (typeof field.resource_label !== "function")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: field.resource_label(rowData),
              }}
            ></div>
          );
        },
      });
    } else if (field.type === "auto") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "radio") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "richtext") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: rowData[field.name] }}
            ></div>
          );
        },
      });
    } else if (field.type === "action") {
      cols.push({
        field: k,
        headerStyle: {
          textAlign: "center",
        },
        title: field.label,
        render: (rowData) => {
          return (
            <div style={{ textAlign: "center" }}>
              <a
                className={{ marginRight: 15 }}
                onClick={(event) => {
                  onAction(field.name, { ...field, trigger: true }, rowData);
                }}
              >
                <i className={`nav-icon ${field.icon} font-weight-bold`}></i>
              </a>
            </div>
          );
        },
      });
    } else {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    }
    // }
    return cols;
  };
  const getLabel = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i].label;
      }
    }
  };
  const displayedInListing = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (
          typeof fields[i].visible !== "undefined" &&
          fields[i].visible === false
        )
          return false;
      }
    }

    return true;
  };
  const isImage = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (fields[i].type === "image") return true;
      }
    }
    return;
  };
  const getField = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i];
      }
    }
    return false;
  };
  const getAdditionalActions = () => {
    let actions = [];
    for (let i in moduleProps.actions) {
      let act = moduleProps.actions[i];
      if (typeof act.trigger === "function") {
        actions.push((rowData) => ({
          icon: act.icon,
          tooltip: act.label,
          actionType: act.actionType,
          onClick: (event, rowData) => {
            let act_opt = Object.apply(actionOpts);
            act_opt[act.name] = {
              openWindow:
                typeof act.openWindow !== "undefined" ? act.openWindow : false,
            };
            setSelectedRow(rowData);
            setActionName(act.label);
            setactionOpts(act_opt);
            setConfirmAction(
              typeof act.component === "undefined" ? true : false
            );
            setOpenActionComponent(
              typeof act.component === "undefined" ? true : false
            );
            setOtherAction(act);

            onAction(act.name, act, rowData);
          },
          disabled: !act.trigger(rowData),
        }));
      } else {
        actions.push({
          icon: act.icon,
          tooltip: act.label,
          onClick: (event, rowData) => {
            setSelectedRow(rowData);
            setActionName(act.label);
            setActionText(act.text);
            setActionUri(act.uri);
            setActionOpenWindow(true);
            setConfirmAction(
              typeof act.component === "undefined" ? true : false
            );
            setOpenActionComponent(
              typeof act.component === "undefined" ? true : false
            );
            setOtherAction(act);
            onAction(act.name, act, rowData);
          },
        });
      }
    }
    if (typeof moduleProps.edit === "undefined" || moduleProps.edit === true) {
      actions.push({
        icon: "i-Pen-2",
        tooltip: "Edit",
        onClick: (event, rowData) => {
          setSelectedRow(rowData);
          onEdit(rowData);
        },
      });
    }

    if (typeof moduleProps.edit === "function") {
      actions.push((rowData) => ({
        icon: "i-Pen-2",
        tooltip: "Edit",
        onClick: (event, rowData) => {
          setSelectedRow(rowData);
          onEdit(rowData);
        },
        disabled: !moduleProps.edit(rowData),
      }));
    }

    if (
      typeof moduleProps.delete === "undefined" ||
      moduleProps.delete === true
    ) {
      actions.push({
        icon: "i-Close-Window",
        tooltip: "Delete",
        onClick: (event, rowData) => {
          setDeletedRow(rowData);
          onDelete(rowData);
        },
      });
    }
    if (typeof moduleProps.delete === "function") {
      actions.push((rowData) => ({
        icon: "i-Close-Window",
        tooltip: "Delete",
        onClick: (event, rowData) => {
          setDeletedRow(rowData);
          onDelete(rowData);
        },
        disabled: !moduleProps.delete(rowData),
      }));
    }

    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: true,
      onClick: () => tableRef.current && tableRef.current.onQueryChange(),
    });

    return actions;
  };

  if (typeof cols !== "undefined") {
    if (cols.length > 0 && cols[0].title !== "#")
      cols.unshift({
        title: "#",
        field: "rowNumber",
        render: (rowData) => rowData.rowNumber,
      });
  }

  const handlePageClick = (evt) => {
    onPageChange(evt.selected);
  };

  let sortableColumn = cols.map((col) => {
    return {
      dataField: col.field,
      text: col.title,
      sort: true,
      align: "left",
      headerAlign: "left",
    };
  });
  let paginationOptions = {
    // custom: true,
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "Pertama",
    prePageText: "Kembali",
    nextPageText: "Selanjutnya",
    lastPageText: "Terakhir",
    nextPageTitle: "Halaman Pertama",
    prePageTitle: "Sebelumnya",
    firstPageTitle: "Selanjutnya",
    lastPageTitle: "Halaman Terakhir",
    showTotal: true,
    totalSize: total_page,
    sizePerPage: per_page ? per_page : 20,
    onPageChange: (page) => {
      console.log({ page });
    },
  };

  if (sortableColumn.length === 0) return <div></div>;
  if (actions.length > 0) {
    sortableColumn.push({
      dataField: "action",
      text: "Aksi",
      sort: true,
      align: "left",
      headerAlign: "center",
    });
    
  }

  return (
    <div>
      <div className="table-responsive">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={
            data
              ? data.map((d, index) => {
                  d.rowNumber = per_page * page + index + 1;
                  
                  cols.map((c) => {
                    d[c.field] = c.render ? c.render(d) : d[c.field];
                  });
                  if(actions.length > 0){
                      d.action = <div>
                            {actions.map((action, idx) => {
                      action = typeof action === 'function' ? action(d) : action;
                      return (
                        <OverlayTrigger
                          key={"a-" + idx}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              {action.tooltip}
                            </Tooltip>
                          }
                        >
                          <span
                            className="cursor-pointer text-{action.actionType} mr-2"
                            onClick={(evt) => {
                              action.onClick(evt, d);
                            }}
                          >
                            {typeof action.icon === "string" ? (
                              <i
                                className={`nav-icon ${action.icon} font-weight-bold`}
                              ></i>
                            ) : action.label}
                            
                          </span>
                        </OverlayTrigger>
                      );
                    })}
                      </div>;
                   }
                  return d;
                })
              : []
          }
          columns={sortableColumn}
          pagination={paginationFactory(paginationOptions)}
          noDataIndication={"Tidak ada data."}
          remote={{
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false,
          }}
          onTableChange={(type, newState) => {
            const {
              page, // newest page
              sizePerPage, // newest sizePerPage
              sortField, // newest sort field
              sortOrder, // newest sort order
              filters, // an object which have current filter status per column
              data, // when you enable remote sort, you may need to base on data to sort if data is filtered/searched
              cellEdit,
            } = newState;
            switch (type) {
              case "filter":
                break;
              case "pagination":
                if (typeof onPageChange !== "undefined") onPageChange(page);
                break;
              case "sort":
                if (typeof onSort !== "undefined")
                  onSort({
                    sortField, // newest sort field
                    sortOrder, // newest sort order
                  });
                break;
              case "cellEdit":
                break;
            }
          }}
        />
      </div>
    </div>
  );
}
