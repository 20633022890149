import React from "react";
import styled from "styled-components";
import { Logo } from "../Assets";

const NavHeader = styled.nav`
  font-family: "Poppins Regular";
  font-size: 12px;
  color: #141823;
  font-size: 20px;
  position: relative;
  box-shadow: none;
  .registerButton {
    background-color: #6d61ec;
    box-shadow: 1px 3px 5px grey;
  }
  .navbar-brand {
    border-bottom: none;
  }
`;

const Navbar = () => {
  return (
    <NavHeader className="navbar navbar-expand-lg">
      <div className="container">
        <a href="/" className="navbar-brand">
          <img
            src={Logo.url}
            alt={Logo.alt}
            width="150px"
            height="auto"
            className="logo"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="zmdi zmdi-hc-fw"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0"></ul>
          <div className="form-inline my-2 my-lg-0">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a
                  href="#afiliasi"
                  className="px-4 py-2bg-none nav-link text-dark"
                >
                  <strong>Afiliasi</strong>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#reseller"
                  className="px-4 py-2 bg-none nav-link text-dark"
                >
                  <strong>Reseller</strong>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#trainning"
                  className="px-4 py-2 bg-none nav-link text-dark"
                >
                  <strong>Training</strong>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/login"
                  className="registerButton btn btn-round mr-4 px-4 py-2 nav-link"
                >
                  Masuk
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </NavHeader>
  );
};

export default Navbar;
