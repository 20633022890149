import {
   
    FaChartBar,
  
  } from "react-icons/fa";
import DashboardContainer from "./Components/DashboardContainer";
import RegistrationModule from "./modules/RegistrationModule";
//import ReportModule from "./modules/ReportModule";
import SetupModule from "./modules/SetupModule";
import BuyModule from "./modules/BuyModule";
import Revenue from "./modules/RevenueModule";
import Withdrawal from "./modules/WithdrawModule";
import Landing from "./modules/LandingModule";
import Reseller from "./modules/ResellerModule";


const modules = [
    {
        name:"Dashboard",
        slug:"dashboard",
        crud:false,
        icon: FaChartBar,
        component: DashboardContainer
    },
    RegistrationModule,
    Landing,
    SetupModule,
    Reseller,
    BuyModule,
    Revenue,
    Withdrawal
]
export default modules;