import React from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyle from "./styles/DashboardStyles";
import TopLoggedInNav from "./common/TopLoggedInNav";
import Sidebar from "./common/Sidebar";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Footer from "./common/Footer";
import DashboardBreadcrumbs from "./common/DashboardBreadcrumbs";

import CallAction from "../redux/actions/CallAction";
import { userHasAccess, hasValue } from "../libs/helper";
import Table from "../Parts/Widgets/Table";
import Button from "../Parts/Widgets/Button";
import accounting from "accounting";
import { FaCartPlus } from "react-icons/fa";
import ModuleForm from "./ModuleForm";
import LabelAndText from "./widgets/LabelAndText";

class BuyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      showSidebar: false,
      pendings: [],
      qty:0,
      fetching: false,
      summary: {
        impressions: 0,
        clicks: 0,
        buys: 0,
        gross: 0,
        sales: 0,
        pendings: 0,
      },
      vouchers: [],
      pos: "listing",
      item: null,
    };
    this.Listing = this.Listing.bind(this);
    this.Buy = this.Buy.bind(this);
    this.Pay = this.Pay.bind(this);
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    const { call_action, callAction } = this.props;
    window.addEventListener("resize", (event) => {
      /*this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });*/
    });

    callAction(call_action, "dashboard", {
      endpoint: "/vouchers",
      scenario: "get",
    });
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action.dashboard !== call_action.dashboard) {
      this.handleUpdate(call_action.dashboard);
    }
    if (_props.call_action.buy_voucher !== call_action.buy_voucher) {
      this.handleSubmit(call_action.buy_voucher);
    }
  }
  handleUpdate(payload) {
    if (!hasValue(payload)) return;

    this.setState({
      fetching: false,

      vouchers: payload.data ? payload.data : [],
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  Listing(props) {
    const { fetching, vouchers } = props;

    return (
      <div style={{ padding: 15 }}>
        <Table
          progress={fetching}
          data={vouchers}
          onAction={(name, action, item) => {
            console.log({ item });
            switch (name) {
              case "buy":
                this.setState({
                  pos: "buy",
                  item,
                });
                break;
              default:
              //do something
            }
          }}
          moduleProps={{
            actions: [
              {
                name: "buy",
                label: "Buy",
                icon: FaCartPlus,
              },
            ],
            fields: [
              {
                name: "nama",
                label: "Voucher",
                type: "text",
              },
              {
                name: "discount",
                label: "Discount",
                type: "text",
                render: (row) => {
                  return `${row.discount * 100}%`;
                },
              },
              {
                name: "harga",
                label: "Price",
                type: "number",
                render: (row) => {
                  return `Rp. ${accounting.formatNumber(row.harga, 0, ".")}`;
                },
              },
            ],
          }}
        />
        <p>*)All vouchers are only valid for 30 days.</p>
      </div>
    );
  }
  submit(input) {
    const { callAction, call_action } = this.props;
    const { item } = this.state;
    const { qty } = input;

    callAction(call_action, "buy_voucher", {
      endpoint: "/voucher/buy",
      data: {
        voucher_id: item.id,
        qty,
      },
      scenario: "post",
    });
    this.setState({
        qty
    });

  }
  handleSubmit(payload) {
    let snackbar = true;
    let snackbarText = "";
    if(payload === null) return;
    if (payload.status === 1) {
      this.setState({
        pos: "pay",
        payment_url: payload.url,
        price: payload.price,
      });
    } else {
      snackbarText = "Cannot process your request. Please try again later!";
      this.setState({ snackbar, snackbarText });
    }
  }
  Buy(props) {
    const { fetching } = props;
    const { item } = this.state;

    return (
      <div style={{ padding: 15 }}>
        <div style={{ maxWidth: 600, padding: 20 }}>
          <LabelAndText label={"Voucher"} text={item.nama} />
          <LabelAndText label={"Discount"} text={`${item.discount * 100}%`} />
          <LabelAndText
            label={"Price"}
            text={`Rp.${accounting.formatNumber(item.harga, 0, ".")}`}
          />
          <LabelAndText label={"Description"} text={item.deskripsi} />
          <ModuleForm
            moduleProps={{
              fields: [
                {
                  name: "qty",
                  label: "Quantity",
                  type: "number",

                },
              ],
            }}
            submitText={"Purchase"}
            progress={fetching}
            onSubmit={(values) => {
              this.submit(values);
            }}
            onError={(invalids) => {
              console.log("invalids:", invalids);
            }}
          />
        </div>
      </div>
    );
  }
  Pay(props) {
    const { fetching } = props;
    const { item, qty, payment_url, price } = this.state;

    return (
      <div style={{ padding: 15 }}>
        <div style={{ maxWidth: 600, padding: 20 }}>
          <LabelAndText label={"Voucher"} text={item.nama} />
         <LabelAndText label={"Discount"} text={`${item.discount * 100}%`} />
          <LabelAndText
            label={"Price"}
            text={`Rp.${accounting.formatNumber(item.harga, 0, ".")}`}
          />
          <LabelAndText label={"Description"} text={item.deskripsi} />
          <LabelAndText label={"Qty"} text={accounting.formatNumber(qty,0,'.')} />
          <LabelAndText label={"Total Price"} text={`Rp. ${accounting.formatNumber(price,0,'.')}`} />
          
          <Button title="Pay Now" onClick={()=>{
              document.location = payment_url;
          }}/>
          <p style={{marginTop:15}}>*)You will be directed to our Payment Processor</p>

        </div>
      </div>
    );
  }
  render() {
    const { classes, moduleProps, modules, history, getSummary } = this.props;
    const { snackbar, snackbarText, fetching, vouchers, pos } = this.state;
    if (fetching) return <p>Please wait...</p>;
    return (
      <div className={classes.root}>
        <TopLoggedInNav history={history} modules={modules} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DashboardBreadcrumbs />
                </Grid>
                <Grid item md={12}>
                  <h1>Buy Voucher</h1>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            {pos === "listing" ? (
              <this.Listing fetching={fetching} vouchers={vouchers} />
            ) : null}
            {pos === "buy" ? <this.Buy fetching={fetching} /> : null}
            {pos === "pay" ? <this.Pay fetching={fetching} /> : null}
          </Grid>
        </Grid>

        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(BuyContainer));
