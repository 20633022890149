import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.div`
  font-weight: 700;
  font-family: "Gilround Bold", sans-serif;
  background-color: rgb(56, 173, 169);
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  color: white;
  max-width: 250px;
  box-shadow: 1px 3px 5px grey;

  :hover {
    background: #000000;
    color: #ffffff;
  }
`;

export default function (props) {
  const { onClick, title, icon, style } = props;
  return (
    <a
      className="btn btn-primary text-white btn-rounded"
      onClick={onClick}
      style={style}
    >
      {typeof icon !== "undefined" ? icon() : title}
    </a>
  );
}
