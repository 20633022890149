import { Field } from "formik";
import React, { useState, useEffect } from "react";
export default function LabelAndCheckbox(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    values,
    id,
    label,
    hint,
    options
  } = props;
  const [value, setValue] = useState(props.value);
  const [checked, setChecked] = useState(false);
 
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
 
  
  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
      <input type="checkbox" value={1} onClick={()=>{
        let val = !checked;
          setChecked(val);
          onChange(val ? 1 : 0);
      }}/> {label}
    </div>
  );
}
