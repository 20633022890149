import React, { useEffect } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const Loading = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  p.txt {
    margin-top: 60px;
  }
`;
export default function (props) {
  const { open } = props;
  return open ? (
    <Loading>
      <Loader type="Puff" color="#1c7c3f" height={80} width={80} />
      <p className={"txt"}>Mohon Tunggu Sebentar...</p>
    </Loading>
  ) : null;
}
