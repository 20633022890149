import React from "react";
import { Modal, Button } from "react-bootstrap";
import CircularProgress from "./CircularProgress";

export default function (props) {
  const { onCancel, title, text, onConfirm, size, progress } = props;
  return (
    <div>
      <Modal
        size={size ? size : 'lg'}
        show={true}
        onHide={() => {
          onCancel();
        }}
      >
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>{progress ? <div>
          <h4>Mohon Tunggu...</h4><CircularProgress/>
        </div>: text}</Modal.Body>
        <Modal.Footer>
          <Button
            title={"Batal"}
            variant="primary"
            onClick={() => {
              onCancel();
            }}
          >
            Batal
          </Button>
          {onConfirm && (
            <Button
              title={"OK"}
              variant="primary"
              onClick={() => {
                onConfirm();
              }}
            >
              Ya
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
